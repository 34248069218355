import React, { useEffect, useState, useCallback, useRef } from 'react'
import Menu from './Menu.js';
import Homepage from './Homepage.js';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate} from 'react-router-dom';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAuth0 } from "@auth0/auth0-react";
import Chip from '@mui/material/Chip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const toArray = selected => Object.keys(selected).map(id => id * 1);

const currency = (v) => {
  const str = ("" + v)
  return "€ " + str
}

const emptyText = <b style={{
  padding: 8,
  border: '1px solid #92278e',
  color: '#92278e',
  borderRadius: 4
}}>No products found</b>


const downloadBlob = (blob, fileName = 'export_shipments_' + Date().toString() + '.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

const SEPARATOR = ',';

const App = () => {

  const navigate = useNavigate();
  const handleOnClick = (url) => window.open(url, "_blank", "noreferrer");



  const getExpiredLabelUrl = async (order_id, type) => {

    handleOpenLoadingScreen();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

    var raw = '{"order_id":"' + order_id + '","type":"' + type + '"}';

    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const response = await fetch("/api/get_expired_label_url", requestOptions);
    const this_url = await response.text();
    console.log(this_url);

    handleCloseLoadingScreen();

    window.open(this_url, "_blank", "noreferrer");
  }


  const [gridRef, setGridRef] = useState(null);

  const { logout, isAuthenticated } = useAuth0();
  const { loginWithRedirect } = useAuth0();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      foto_field: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      bulk_item: {
        width: '120px',
        margin: '0px 0px 10px 10px',
        textAlign: 'center',
        height: '58px',
        paddingTop: '5px',
        color: '#555e68',
        '&:hover': {
          cursor: 'pointer',
          color: '#9452c0',
          border: '1px solid #9452c0'
        },
      },
      bulk_icon: {
        color: '#000'
      },
      bulk_icon_inactive: {
        color: '#97999c'
      },
      basic_link: {
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline'
        }
      }
    }),
  );


  //get the url parameters for initial filtering
  const queryParams = new URLSearchParams(window.location.search);
  const order_filter = queryParams.get('order') ?? '';
  const status_filter = queryParams.get('status_filter') ?? '';

  const defaultFilterValue = []

  if(order_filter) {
    defaultFilterValue.push({ name: 'order_id', operator: 'eq', type: 'string', value: order_filter })
  }

  const defaultStatusValue = status_filter

  const classes = useStyles();

  const ColorButton = styled(Button)(({ theme }) => ({
    color: '#0089ff',
    backgroundColor: 'transparent',
    fontSize: '14px',
    textTransform: 'none',
    justifyContent: 'flex-start',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  }));

  const OpenLabelButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#1976d2',
    fontSize: '12px',
    border: '1px solid #000',
    textTransform: 'none',
    borderRadius: '5px',
    justifyContent: 'center',
    padding: '3px',
    '&:hover': {
      backgroundColor: '#6ba8e4',
    },
  }));


  const defaultSortInfo = { name: 'label_id', dir: -1 }

  const defaultColumns = [
    { name: 'id',					      	      header: 'Id', sortable: false, type: 'number', defaultFlex: 1, defaultVisible: false },
    { name: 'label_id',					      	header: 'Label Id', sortable: true, type: 'number', defaultFlex: 1, defaultVisible: false },
    { name: 'order_id',						      header: 'Order Number', sortable: false,  defaultWidth: 180  },
    { name: 'marketplace',					    header: 'Marketplace', sortable: false, defaultWidth: 110  },
    { name: 'label_creation_date',      header: 'Label Creation Date', sortable: false, type: 'date', defaultWidth: 170 },
    { name: 'shipping_carrier',         header: 'Carrier',sortable: false,  defaultWidth: 90 },
    { name: 'ship_to_country',          header: 'Destination',sortable: false,  defaultWidth: 100  },
    { name: 'tracking_number',          header: 'Tracking Number',sortable: false,  defaultWidth: 160, render: ({ value, data }) => {return value !== '' ? <div style={{ display: 'inline-block' }}><ColorButton className={classes.button_name} onClick={() => {handleOnClick(data.tracking_url)}}>{value}</ColorButton></div> : '' } },
    { name: 'label_url',                header: 'Label',sortable: false,  defaultWidth: 120, render: ({ value, data }) => {return value && value !== '' ? <div style={{ display: 'inline-block' }}><ColorButton className={classes.button_name} onClick={() => {data.label_expired === 'No' ? handleOnClick(data.label_url) : getExpiredLabelUrl(data.order_id, '')}}>Open Label</ColorButton></div> : '' } },
    { name: 'return_label_url',         header: 'Return Label',sortable: false, defaultWidth: 160, render: ({ value, data }) => {return value && value !== '' ? <div style={{ display: 'inline-block' }}><ColorButton className={classes.button_name} onClick={() => {data.label_expired === 'No' ? handleOnClick(data.return_label_url) : getExpiredLabelUrl(data.order_id, 'return')}}>Open Return Label</ColorButton></div> : '' } },
    { name: 'creation_date',            header: 'Order Date', sortable: false, type: 'date', defaultWidth: 170 },
    { name: 'latest_delivery_date',     header: 'Delivery Deadline', sortable: false, defaultVisible: false,  type: 'date',  defaultWidth: 160 },
    { name: 'days_till_delivery_deadline', header: 'Days to Deadline', sortable: false, defaultWidth: 140, render: ({ value, data }) => {return value !== '' ? <div style={{ display: 'inline-block' }}><Chip label={value} color={data.critical} size="small" /></div> : '' } },
    { name: 'shipping_status',          header: 'Status',	sortable: false, defaultFlex: 1 },
    { name: 'shipping_phase',           header: 'Phase', sortable: false, defaultFlex: 1 },
    { name: 'critical',                 header: 'Critical', sortable: false, defaultWidth: 160, defaultVisible: false, }
  ];

  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const [filterStatusValue, setFilterStatusValue] = useState(defaultStatusValue);

  const [dataSource, setDataSource] = useState([]);
  const [columns] = useState(defaultColumns);


  const [selected, setSelected] = useState({});

  const [selectionActive, setSelectionActive] = useState(false);

  const onSelectionChange = useCallback(({ selected }) => {
      setSelected(selected)
      setSelectionActive(toArray(selected).length > 0)
  }, [])


  const loadData = () => {
    const newDataSource = () => {
      const limit = 50;
      const requestOptions = {
        method: 'GET',
        headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };
      return fetch('/api/get_shipments' + '?status_filter=' + filterStatusValue + '&filterBy='+JSON.stringify(filterValue), requestOptions)
        .then(response => {
          const totalCount = response.headers.get('X-Total-Count');
          return response.json().then(data => {
            return data;
          })
        });
      }

    setDataSource(newDataSource)
  }


  const clear_filters = () => {
    setOrder_select('')
    setStatus_select('')
  }

  const [order_filter_value, setOrder_filter_value] = useState("");

  const search_click = () => {
    navigate("/shipping_status?status_filter=" + status_select + "&order=" + order_select);
    const newFilterValue = []

    if(order_select) {
      newFilterValue.push({ name: 'order_id', operator: 'eq', type: 'string', value: order_select })
    }

    const newStatusValue = status_select

    const requestOptions = {
      method: 'GET',
      headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };

    const newDataSource = () => {
      return fetch('/api/get_shipments' + '?status_filter=' + newStatusValue + '&filterBy='+JSON.stringify(newFilterValue), requestOptions)
        .then(response => {
          const totalCount = response.headers.get('X-Total-Count');
          return response.json().then(data => {
            return data;
          })
        });
      }
    setDataSource(newDataSource)
    console.log('Refreshed?')
  }


  //Load the first dataset
  useEffect(() => {
    loadData();
  }, [])

  const [order_select, setOrder_select] = React.useState(order_filter);
  const [status_select, setStatus_select] = React.useState(status_filter);

  const handleChange_orderfilter = (event: SelectChangeEvent) => {
    setOrder_select(event.target.value);
  };

  const handleChange_statusfilter = (event: SelectChangeEvent) => {
    setStatus_select(event.target.value);
  };

  const requestOptions = {
    method: 'GET',
    headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
  };

  //-----------------------------------------------

  const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;

    const header = columns.map((c) => c.name).join(SEPARATOR);
    const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(SEPARATOR));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob);
  };


  const [labelsUrl, setLabelsUrl] = React.useState('');

  const exportSelectedLabels = () => {

      if( toArray(selected).length > 0 ) {

      handleOpenLoadingScreen()

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

      var my_orders = ''

      toArray(selected).forEach((order, index) => {
        my_orders = my_orders + "'" + order + "',"
      });

      var raw = '{"order_ids":"' + my_orders.slice(0, -1) + '"}';

      console.log(raw)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("/api/export_labels", requestOptions)
        .then(response => response.text())
        .then(result => {
          setLabelsUrl(result)
          handleCloseLoadingScreen();
        }
      )
        .catch(error => console.log('error', error));
      }
    };


    const [openLoadingScreen, setOpenLoadingScreen] = React.useState(false);
    const handleCloseLoadingScreen = () => {
      setOpenLoadingScreen(false);
    };
    const handleOpenLoadingScreen = () => {
      setOpenLoadingScreen(true);
    };

    const handleOpenFile = () => {
      window.open(labelsUrl, "_blank", "noreferrer");
    };


  const gridStyle = { marginTop: 10, height: '80vh' }

  if (isAuthenticated) {

  return (

    <div>

    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoadingScreen}
        onClick={handleCloseLoadingScreen}
      >
        <CircularProgress color="inherit" />
    </Backdrop>

    <Grid container>

      <Grid item xs="auto">

        <Menu navigate={navigate} page={'shipments'}/>

      </Grid>

      <Grid item xs>

    <div>

    <div style={{marginTop: '55px', padding: '10px 20px 10px 20px'}}>

    <div style={{margin: '5px 0px 5px 0px', height: '60px'}}>

    <Grid container justifyContent="space-between">

    <Grid item>

          <Grid container spacing={1}>
            <Grid item>
              <TextField id="outlined-basic" label="Order Number" variant="outlined" size="small" value={order_select} onChange={handleChange_orderfilter} />
          </Grid>

          <Grid item>
            <FormControl sx={{  minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Status</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={status_select}
                  label="Status"
                  onChange={handleChange_statusfilter}
                >
                  <MenuItem value="">All status</MenuItem>
                  <MenuItem key="Delivered" value="Delivered">Delivered</MenuItem>
                  <MenuItem key="Close_to_deadline" value="Close_to_deadline">Close to deadline</MenuItem>
                  <MenuItem key="Late" value="Late">Late</MenuItem>
                </Select>
              </FormControl>
            </Grid>

          <Grid item>
            <Button onClick={() => search_click()} style={{marginLeft: 10}}variant="contained" >Search</Button>
          </Grid>

          <Grid item>
            <Button onClick={() => clear_filters()} style={{marginLeft: 10}}variant="outlined" >Clear all</Button>
          </Grid>

          </Grid>
      </Grid>

      <Grid item>

      <Grid container>

            <Grid item>
            {labelsUrl && <OpenLabelButton variant="text" onClick={handleOpenFile}>Download Label File</OpenLabelButton>}
            </Grid>

            <Grid item>
            <Card variant="outlined" className={classes.bulk_item} onClick={exportSelectedLabels}>
              <FileDownloadIcon className={classes.bulk_icon} />
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                Export Labels
              </Typography>
            </Card>
          </Grid>

        </Grid>

      </Grid>


      </Grid>

      </div>

      <br />

      <ReactDataGrid
          handle={setGridRef}
          idProperty="id"
          selected={selected}
          style={gridStyle}
          rowHeight={40}
          showZebraRows={false}
          columns={columns}
          emptyText={emptyText}
          pagination="local"
          defaultLimit={100}
          dataSource={dataSource}
          defaultSortInfo={defaultSortInfo}
          checkboxColumn
          onSelectionChange={onSelectionChange}
          enableKeyboardNavigation={false}
      />

      </div>

      </div>

      </Grid>

    </Grid>

      </div>
  )
}
else {
  return (
      <Homepage />
)
}
}

export default () => <App />
