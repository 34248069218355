import React, { useEffect, useState, useCallback, useRef } from 'react'
import Menu from './Menu.js';
import Homepage from './Homepage.js';
import { useNavigate} from 'react-router-dom';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { useAuth0 } from "@auth0/auth0-react";
import Chip from '@mui/material/Chip';
import PrintIcon from '@mui/icons-material/Print';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SyncIcon from '@mui/icons-material/Sync';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const emptyText = <b style={{
  padding: 8,
  border: '1px solid #92278e',
  color: '#92278e',
  borderRadius: 4
}}>No products found</b>


const App = () => {

  //Navigation
  const navigate = useNavigate();

  const handleOnClickProduct = (ean) => navigate('/Product?language=nl-NL&id=' + ean);

  const [gridRef, setGridRef] = useState(null);

  const { isAuthenticated } = useAuth0();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      bulk_item: {
        width: '120px',
        margin: '0px 5px 10px 5px',
        textAlign: 'center',
        height: '58px',
        paddingTop: '5px',
        color: '#555e68',
        '&:hover': {
          cursor: 'pointer',
          border: '1px solid #9452c0'
        },
      },
      bulk_item_right: {
        width: '120px',
        margin: '0px 0px 10px 5px',
        textAlign: 'center',
        height: '58px',
        paddingTop: '5px',
        color: '#555e68',
        '&:hover': {
          cursor: 'pointer',
          border: '1px solid #9452c0'
        },
      },
      bulk_item_left: {
        width: '120px',
        margin: '0px 10px 10px 0px',
        textAlign: 'center',
        height: '58px',
        paddingTop: '5px',
        color: '#555e68',
        '&:hover': {
          cursor: 'pointer',
          border: '1px solid #9452c0'
        },
      },
      bulk_icon: {
        color: '#000'
      },
    }),
  );

  //get the url parameters for initial filtering
  const queryParams = new URLSearchParams(window.location.search);
  const order_filter = queryParams.get('order') ?? '';
  const status_filter = queryParams.get('status_filter') ?? '';

  const defaultFilterValue = []

  if(order_filter) {
    defaultFilterValue.push({ name: 'order_id', operator: 'eq', type: 'string', value: order_filter })
  }

  const defaultStatusValue = status_filter

  const classes = useStyles();

  const LabelButton = styled(Button)(({ theme }) => ({
    color: '#000',
    backgroundColor: '#fff',
    fontSize: '12px',
    border: '1px solid #000',
    textTransform: 'none',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    padding: '3px',
    '&:hover': {
      backgroundColor: '#eee',
    },
  }));

  const OpenLabelButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#1976d2',
    fontSize: '12px',
    border: '1px solid #000',
    textTransform: 'none',
    borderRadius: '5px',
    justifyContent: 'center',
    padding: '3px',
    '&:hover': {
      backgroundColor: '#6ba8e4',
    },
  }));

  const FulfillButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#9c27b0',
    fontSize: '12px',
    textTransform: 'none',
    borderRadius: '5px',
    justifyContent: 'center',
    padding: '3px',
    '&:hover': {
      backgroundColor: '#7b208a',
    },
  }));

  const defaultSortInfo = { name: 'id', dir: -1 }

  function get_rowspan({ value, data, dataSourceArray, rowIndex, column }) {
    let rowspan = 1;

    const prevData = dataSourceArray[rowIndex - 1];
    if (prevData && prevData['order_id'] === data.order_id) {
      return rowspan;
    }
    let currentRowIndex = rowIndex + 1;
    while (
      dataSourceArray[currentRowIndex] &&
      dataSourceArray[currentRowIndex]['order_id'] === data.order_id
    ) {
      rowspan++;
      currentRowIndex++;
      if (rowspan > 9) {
        break;
      }
    }
    return rowspan;
  }


  const ColorButton = styled(Button)(({ theme }) => ({
    color: '#1976d2',
    backgroundColor: 'transparent',
    fontSize: '14px',
    textTransform: 'none',
    textAlign: 'left',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  }));

  const BlackButton = styled(Button)(({ theme }) => ({
    color: '#555b61',
    backgroundColor: 'transparent',
    fontSize: '14px',
    textTransform: 'none',
    textAlign: 'left',
    padding: '0px',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  }));

  const [orderChangeAddress, setOrderChangeAddress] = useState('');
  const [orderUpdate, setOrderUpdate] = useState('');
  const [openAddress, setOpenAddress] = React.useState(false);
  const [openOrderUpdate, setOpenOrderUpdate] = React.useState(false);
  const [name, setName] = React.useState('');
  const [street, setStreet] = React.useState('');
  const [area, setArea] = React.useState('');
  const [housenumber, setHousenumber] = React.useState('');
  const [city, setCity] = React.useState('');

  const handleCloseAddress = () => {
    setOpenAddress(false);
  };

  const handleSubmitChangeAddress = () => {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

      var raw = '{"order_id":"' + orderChangeAddress + '",';
      raw = raw + '"recipient_name":"' + name + '",';
      raw = raw + '"ship_to_street":"' + street + '",';
      raw = raw + '"ship_to_housenumber":"' + housenumber + '",';
      raw = raw + '"ship_to_city":"' + city + '",';
      raw = raw + '"ship_to_area":"' + area + '"}';

      console.log(raw)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("/api/update_ship_to_address", requestOptions)
        .then(response => response.text())
        .then(result => loadData())
        .catch(error => console.log('error', error));

      setOpenAddress(false);
    };

    const handleOnClickAddress = (order_id, recipient_name, ship_to_street, ship_to_area, ship_to_housenumber, ship_to_city) => {
      setOrderChangeAddress(order_id);
      setName(recipient_name);
      setStreet(ship_to_street);
      setArea(ship_to_area);
      setHousenumber(ship_to_housenumber);
      setCity(ship_to_city);
      setOpenAddress(true);
    };


    const handleOnClickOrder = (order_id) => {
      setOrderUpdate(order_id);
      setOpenOrderUpdate(true);
    };

    const handleCloseOrderUpdate = () => {
      setOpenOrderUpdate(false);
    };


  const [orderChangeLabel, setOrderChangeLabel] = useState('');
  const [open, setOpen] = React.useState(false);

  const [newLabelChoice, setNewLabelChoice] = React.useState('');

  const handleChangeLabelChoice = (event) => {
    setNewLabelChoice(event.target.value);
  };

  const handleOnClick = (order_id, label_id, label_name) => {
    setOrderChangeLabel(order_id);
    setNewLabelChoice(label_id);
    setOpen(true);
  };


  const handleSubmitChangeLabel = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

    var raw = '{"order_id":"' + orderChangeLabel + '",';
    raw = raw + '"label_id":"' + newLabelChoice + '"}';

    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("/api/update_label_id", requestOptions)
      .then(response => response.text())
      .then(result => loadData())
      .catch(error => console.log('error', error));

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleCreateLabel = (order_id) => {

    handleOpenLoadingScreen()

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

    var raw = '{"order_id":"' + order_id + '"}';

    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("/api/create_label", requestOptions)
      .then(response => response.text())
      .then(result => {
        handleCloseLoadingScreen();
        loadData()
      }
    )
      .catch(error => console.log('error', error));
  };


  const handleRemoveOrder = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

    var raw = '{"order_id":"' + orderUpdate + '"}';

    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("/api/remove_order_from_picklist", requestOptions)
      .then(response => response.text())
      .then(result => loadData())
      .catch(error => console.log('error', error));

    setOpenOrderUpdate(false);
  };



  const handleFulfillOrder = (order_id) => {

    handleOpenLoadingScreen()

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

    var raw = '{"order_id":"' + order_id + '"}';

    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("/api/fulfill_order", requestOptions)
      .then(response => response.text())
      .then(result => {
        handleCloseLoadingScreen();
        loadData()
      }
      )
      .catch(error => console.log('error', error));

    };


    const handleOpenLabel = (label_url) => {
      window.open(label_url, "_blank", "noreferrer");
    };

    const alternativeImage = 'https://aasproductimages.s3.eu-central-1.amazonaws.com/no_image_available.svg'


  const defaultColumns = [
    { name: 'id',					      	      header: 'Id', editable: false, sortable: false, type: 'number', defaultFlex: 1, defaultVisible: false },
    { name: 'order_id',						      header: 'Order Number', editable: false, sortable: false,  defaultWidth: 180, render: ({ value, data }) => {return <div style={{ display: 'inline-block' }}><BlackButton style={{justifyContent: "flex-start"}} onClick={() => {handleOnClickOrder(data.order_id)}}><b>{value}</b></BlackButton><br />{data.recipient_name}<br />{data.creation_date}</div> }, rowspan: ({ value, data, dataSourceArray, rowIndex, column }) => get_rowspan({ value, data, dataSourceArray, rowIndex, column }) , onRender: (cellProps, {data}) => {cellProps.style.background = !data.recipient_name && '#f6f8fa'} },
    { name: 'marketplace',					    header: 'Marketplace', editable: false, sortable: false, defaultWidth: 100, rowspan: ({ value, data, dataSourceArray, rowIndex, column }) => get_rowspan({ value, data, dataSourceArray, rowIndex, column }), onRender: (cellProps, {data}) => {cellProps.style.background = !data.recipient_name && '#f6f8fa'} },
    { name: 'recipient_name',           header: 'Ship To', editable: false, sortable: false,  defaultWidth: 230, render: ({ value, data }) => {return data.recipient_name && <div><ColorButton style={{justifyContent: "flex-start"}} onClick={() => {handleOnClickAddress(data.order_id, data.recipient_name, data.ship_to_street, data.ship_to_area, data.ship_to_housenumber, data.ship_to_city)}}>{data.ship_to_street + ' ' + data.ship_to_housenumber}<br />{data.ship_to_postal_code + ' ' + data.ship_to_city + ' ' + data.ship_to_country}<br />{data.ship_to_area}</ColorButton></div> }, rowspan: ({ value, data, dataSourceArray, rowIndex, column }) => get_rowspan({ value, data, dataSourceArray, rowIndex, column }) , onRender: (cellProps, {data}) => {cellProps.style.background = !data.recipient_name && '#f6f8fa'} },
    { name: 'shipping_deadline',        header: 'Shipping Deadline', editable: false, sortable: false, type: 'date', defaultWidth: 150, render: ({ value, data }) => {return <div style={{ display: 'inline-block' }}>{value}<br /><span style={{color: data.time_to_ship > 2 ? 'black' : 'red'}}>{data.time_to_ship + ' hours'}</span></div> }, rowspan: ({ value, data, dataSourceArray, rowIndex, column }) => get_rowspan({ value, data, dataSourceArray, rowIndex, column }) , onRender: (cellProps, {data}) => {cellProps.style.background = !data.recipient_name && '#f6f8fa'} },
    { name: 'label_id',					      	header: 'Label Id', defaultWidth: 50, editable: false, sortable: false, type: 'number', defaultVisible: false },
    { name: 'label_name',               header: 'Label Type (click to change)', sortable: false, editable: false, defaultWidth: 300, render: ({ value, data }) => {return <div style={{ display: 'inline-block' }}>{data.label_needed === 'Yes' && <ColorButton style={{ color: value ? '#1976d2' : 'red'}} className={classes.button_name} onClick={() => {handleOnClick(data.order_id, data.label_id, data.label_name)}}>{value ? value : 'Choose label'}</ColorButton>}</div> }, rowspan: ({ value, data, dataSourceArray, rowIndex, column }) => get_rowspan({ value, data, dataSourceArray, rowIndex, column }) , onRender: (cellProps, {data}) => {cellProps.style.background = !data.recipient_name && '#f6f8fa'} },
    { name: 'label_needed',              header: 'Create Label', editable: false, sortable: false,  defaultWidth: 150, render: ({ value, data }) => {return <div style={{ display: 'inline-block' }}>{data.recipient_name && data.label_id && !data.label_url && data.label_needed === 'Yes' && <LabelButton className={classes.button_name} onClick={() => {handleCreateLabel(data.order_id)}}>Create Label</LabelButton>} {data.label_url && data.label_needed === 'Yes' && <OpenLabelButton className={classes.button_name} onClick={() => {handleOpenLabel(data.label_url)}}>Label</OpenLabelButton>} {data.label_url && data.label_needed === 'Yes' && data.return_label_url && <OpenLabelButton className={classes.button_name} onClick={() => {handleOpenLabel(data.return_label_url)}}>Return</OpenLabelButton>} </div> }, rowspan: ({ value, data, dataSourceArray, rowIndex, column }) => get_rowspan({ value, data, dataSourceArray, rowIndex, column }) , onRender: (cellProps, {data}) => {cellProps.style.background = !data.recipient_name && '#f6f8fa'} },
    { name: 'fulfill_url',              header: 'Fulfill', editable: false, sortable: false, defaultWidth: 90, render: ({ value, data }) => {return <div style={{ display: 'inline-block' }}>{(data.label_needed === 'No' || data.label_url) && <FulfillButton className={classes.button_name} onClick={() => {handleFulfillOrder(data.order_id)}}>Fulfill</FulfillButton>}</div> }, rowspan: ({ value, data, dataSourceArray, rowIndex, column }) => get_rowspan({ value, data, dataSourceArray, rowIndex, column }) , onRender: (cellProps, {data}) => {cellProps.style.background = !data.recipient_name && '#f6f8fa'} },
    { name: 'quantity',                 header: '#', editable: false, sortable: false, defaultWidth: 50, render: ({ value, data }) => {return value > 1 ? <Chip label={value + 'x'} color="primary" size = 'small'/> : value + 'x' }, onRender: (cellProps, {data}) => {cellProps.style.background = !data.recipient_name && '#f6f8fa'} },
    { name: 'main_photo',						    header: 'Image', editable: false,	sortable: false, defaultWidth: 85, cellProps: {style: { padding: '0px', cursor: 'pointer'}}, render: ({ value, data }) => {return (<div style={{width: 'auto', height: '60px'}}> <img alt={data.ean} onClick={() => {handleOnClickProduct(data.ean)}} src={value ? value : alternativeImage} style={{width: '100%', height: '100%', objectFit: 'contain'}} /></div>) }, onRender: (cellProps, {data}) => {cellProps.style.background = !data.recipient_name && '#f6f8fa'} },
    { name: 'product_title',            header: 'Product', editable: false, sortable: false, defaultWidth: 500, defaultFlex: 1, render: ({ value, data }) => {return <div style={{ display: 'inline-block' }}><b>{data.ean}</b><br />{value}<br />{data.brand && data.brand} {data.color && ' - ' + data.color} {data.size && ' - ' + data.size}</div> }  , onRender: (cellProps, {data}) => {cellProps.style.background = !data.recipient_name && '#f6f8fa'} }

  ];


  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const [filterStatusValue, setFilterStatusValue] = useState(defaultStatusValue);

  const [dataSource, setDataSource] = useState([]);
  const [columns] = useState(defaultColumns);

  const loadData = () => {
    const newDataSource = () => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };
      return fetch('/api/get_orders_to_pick' + '?status_filter=' + filterStatusValue + '&filterBy='+JSON.stringify(filterValue), requestOptions)
        .then(response => {
          return response.json().then(data => {
            return data;
          })
        });
      }

    setDataSource(newDataSource)

    handleCloseLoadingScreen()
  }


  //Load the first dataset
  useEffect(() => {
    loadData();
  }, [])


  const [labelOptions, setLabelOptions] = useState([]);

  const requestOptions = {
    method: 'GET',
    headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
  };

  useEffect(() => {
    const getLabelList = async () => {
      const res = await fetch("/api/get_label_options", requestOptions);
      //console.log(res);
      const response = await res.json();
      setLabelOptions(response);
    };
    getLabelList();
  }, []);


  //-----------------------------------------------

   const reloadTrigger = () => {
     loadData();
   };

  const [picklistUrls, setPicklistUrls] = React.useState('');

  const exportCSV = () => {

    handleOpenLoadingScreen()

    fetch(
      '/api/print_picklist',
      {
        method: 'GET'
      }
    )
    .then(response => response.text())
    .then(result => {

      setPicklistUrls(result)
      handleCloseLoadingScreen()

    }
    )
    .catch(error => console.log('error', error));

    setOpenAmazonAlert(true)

  };


  const gridStyle = { marginTop: 10, height: '80vh' }

  const onEditComplete = useCallback(({ value, columnId, rowId }) => {

  }, [dataSource])


  const changeHandlerFile = (event) => {
    console.log('Start upload file')
    handleAmazonFileSubmission(event.target.files[0]);
  };


  const handleAmazonFileSubmission = (my_file_to_upload) => {

    handleOpenLoadingScreen();

    const formData = new FormData();

    formData.append('File', my_file_to_upload);
    formData.append('type', 'amazon_order_file');

    fetch(
      '/api/include_amazon_order_data',
      {
        method: 'POST',
        body: formData,
      }
    )
    .then(response => response.text())
    .then(result => {
      console.log(result);
      setAmazonAlertText(result);
      handleCloseLoadingScreen();
      loadData()
    }
    )
    .catch(error => console.log('error', error));

    setOpenAmazonAlert(true)

  };


  const [openAmazonAlert, setOpenAmazonAlert] = React.useState(false);
  const [amazonAlertText, setAmazonAlertText] = React.useState('In progress, please wait....');

  const handleCloseAmazonAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAmazonAlert(false);
    setAmazonAlertText('In progress, please wait....');

  };


  const [openLoadingScreen, setOpenLoadingScreen] = React.useState(false);
  const handleCloseLoadingScreen = () => {
    setOpenLoadingScreen(false);
  };
  const handleOpenLoadingScreen = () => {
    setOpenLoadingScreen(true);
  };



  const onChangeData = (e) => {
      const { target: { name, value } } = e;

      if(name === 'name'){
        setName(value);
      }

      if(name === 'street'){
        setStreet(value);
      }

      if(name === 'area'){
        setArea(value);
      }

      if(name === 'housenumber'){
        setHousenumber(value);
      }

      if(name === 'city'){
        setCity(value);
      }
  };


  const [labelsUrl, setLabelsUrl] = React.useState('');

  const exportSelectedLabels = () => {

      handleOpenLoadingScreen()

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch("/api/export_created_labels", requestOptions)
        .then(response => response.text())
        .then(result => {
          setLabelsUrl(result)
          handleCloseLoadingScreen();
        }
      )
        .catch(error => console.log('error', error));

    };


  const handleOpenFile = () => {
      window.open(labelsUrl, "_blank", "noreferrer");
    };



    const [returnLabelsUrl, setReturnLabelsUrl] = React.useState('');

    const exportReturnLabels = () => {

        handleOpenLoadingScreen()

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch("/api/export_created_return_labels", requestOptions)
          .then(response => response.text())
          .then(result => {
            setReturnLabelsUrl(result)
            handleCloseLoadingScreen();
          }
        )
          .catch(error => console.log('error', error));

      };


    const handleOpenReturnFile = () => {
        window.open(returnLabelsUrl, "_blank", "noreferrer");
      };




  if (isAuthenticated) {

  return (


    <div>

    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoadingScreen}
        onClick={handleCloseLoadingScreen}
      >
        <CircularProgress color="inherit" />
    </Backdrop>

    <Grid container>

      <Grid item xs="auto">

        <Menu navigate={navigate} page={'order_picking'}/>

      </Grid>

      <Grid item xs>

    <div>

    <div style={{marginTop: '55px', padding: '10px 20px 10px 20px'}}>

    <div style={{margin: '5px 0px 5px 0px', height: '60px'}}>

    <Grid container justifyContent="space-between">

    <Grid item>

    <Grid container>

      <Grid item>
          <Card variant="outlined" className={classes.bulk_item_left} onClick={reloadTrigger}>
            <SyncIcon className={classes.bulk_icon} />
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              Refresh
            </Typography>
          </Card>
      </Grid>

      <Grid item>
      <Card variant="outlined" className={classes.bulk_item} onClick={exportCSV}>
        <PrintIcon className={classes.bulk_icon} />
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Create Picklist
        </Typography>
      </Card>
    </Grid>

      <Grid item>

      <Grid container>
        {picklistUrls !== '' && picklistUrls.split(',').map((url, index) => (
          <Grid item style={{ margin: '0px 0px 0px 20px', fontSize: '14px' }}><OpenLabelButton className={classes.button_name} onClick={() => {handleOpenLabel(url)}}>Open latest picklist PDF</OpenLabelButton></Grid>
        ))}
      </Grid>

    </Grid>

    </Grid>

  </Grid>

      <Grid item>

      <Grid container>

              <Grid item>
              {labelsUrl && <OpenLabelButton variant="text" onClick={handleOpenFile}>Download Label File</OpenLabelButton>}
              </Grid>

              <Grid item>
              <Card variant="outlined" className={classes.bulk_item} onClick={exportSelectedLabels}>
                <FileDownloadIcon className={classes.bulk_icon} />
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  All Labels
                </Typography>
              </Card>
            </Grid>

            <Grid item>
            {returnLabelsUrl && <OpenLabelButton variant="text" onClick={handleOpenReturnFile}>Download Return Labels</OpenLabelButton>}
            </Grid>

            <Grid item>
            <Card variant="outlined" className={classes.bulk_item} onClick={exportReturnLabels}>
              <FileDownloadIcon className={classes.bulk_icon} />
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                All Return Labels
              </Typography>
            </Card>
          </Grid>

            <Grid item>
            <Button disableRipple variant="outlined" component="label" className={classes.bulk_item_right} sx={{ml:3, textTransform: 'capitalize', border: '1px solid #e0e0e0', color: 'gray', height: '65px'}}>

               <input hidden type="file" accept=".txt" name="file" onChange={changeHandlerFile} />
                Upload Amazon File

            </Button>
            </Grid>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAmazonAlert} autoHideDuration={10000} onClose={handleCloseAmazonAlert}>
              <Alert severity="info" >
                {amazonAlertText}
              </Alert>
            </Snackbar>

        </Grid>

      </Grid>

      </Grid>

      </div>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
        <DialogContentText>Change label type for order <b>{orderChangeLabel}</b>
        </DialogContentText>
        <br />
        <Select
          labelId="label_select_choice"
          id="label_select"
          value={newLabelChoice}
          label="Label"
          onChange={handleChangeLabelChoice}
          fullWidth
        >
        {labelOptions.map((attribute) => (
          <MenuItem key={attribute.label_id} value={attribute.label_id}>{attribute.label_name}</MenuItem>
        ))}
        </Select>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitChangeLabel}>Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openOrderUpdate} onClose={handleCloseOrderUpdate} fullWidth maxWidth="sm">
        <DialogContent>
        <DialogContentText color="error">Remove order <b>{orderUpdate}</b> from pick list?
        </DialogContentText>
        <br />
        <i>The order will not be fulfilled or shipped. For example, when the order is cancelled in the marketplace.</i>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOrderUpdate}>Cancel</Button>
          <Button variant="outlined" color="error" onClick={handleRemoveOrder}>Remove</Button>
        </DialogActions>
      </Dialog>

      <br />

      <Dialog open={openAddress} onClose={handleCloseAddress} fullWidth maxWidth="sm">
        <DialogContent>
        <DialogContentText>Change address for order <b>{orderChangeAddress}</b>
        </DialogContentText>
        <br />
        <TextField
          name="name"
          label="Name"
          value={name}
          margin="dense"
          id="name"
          fullWidth
          variant="outlined"
          onChange={onChangeData}
        />
        <br />
        <TextField
          name="street"
          label="Primary Adress Line"
          value={street}
          margin="dense"
          id="street"
          fullWidth
          variant="outlined"
          onChange={onChangeData}
        />
        <br />
        <TextField
          name="area"
          label="Additional Adress Line"
          value={area}
          margin="dense"
          id="area"
          fullWidth
          variant="outlined"
          onChange={onChangeData}
        />
        <br />
        <TextField
          name="housenumber"
          label="Housenumber (only numbers)"
          value={housenumber}
          margin="dense"
          id="housenumber"
          variant="outlined"
          onChange={onChangeData}
        />
        <br />
        <TextField
          name="city"
          label="City"
          value={city}
          margin="dense"
          id="city"
          fullWidth
          variant="outlined"
          onChange={onChangeData}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddress}>Cancel</Button>
          <Button onClick={handleSubmitChangeAddress}>Update</Button>
        </DialogActions>
      </Dialog>

      <ReactDataGrid
          handle={setGridRef}
          idProperty="id"
          style={gridStyle}
          rowHeight={85}
          showZebraRows={false}
          columns={columns}
          emptyText={emptyText}
          pagination="local"
          defaultLimit={100}
          dataSource={dataSource}
          defaultSortInfo={defaultSortInfo}
          editable={false}
          onEditComplete={onEditComplete}
          enableSelection={false}
          enableKeyboardNavigation={false}
      />

      </div>

      </div>

      </Grid>

    </Grid>

      </div>
  )
}
else {
  return (
      <Homepage />
)
}
}

export default () => <App />
