import React, { useEffect, useState, useCallback, useRef } from 'react'
import Menu from './Menu.js';
import Homepage from './Homepage.js';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate} from 'react-router-dom';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DataArrayIcon from '@mui/icons-material/DataArray';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAuth0 } from "@auth0/auth0-react";
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const toArray = selected => Object.keys(selected).map(id => id * 1);

const emptyText = <b style={{
  padding: 8,
  border: '1px solid #92278e',
  color: '#92278e',
  borderRadius: 4
}}>Click search to view products</b>


const downloadBlob = (blob, fileName = 'export_' + Date().toString() + '.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

const SEPARATOR = ',';

const App = () => {

  const navigate = useNavigate();

  const [gridRef, setGridRef] = useState(null);

  const { logout, isAuthenticated } = useAuth0();
  const { loginWithRedirect } = useAuth0();

  const handleOnClick = (id) => navigate('/Product?language=nl-NL&id=' + id);

  const handleOnClickVariants = (variant) => {
    const url = "/Products?ean=&brand=&size=&color=&stock=&variant=" + variant
    window.location.href = url
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({

      bulk_item: {
        width: '120px',
        margin: '0px 0px 10px 10px',
        textAlign: 'center',
        height: '58px',
        paddingTop: '5px',
        color: '#555e68',
        '&:hover': {
          cursor: 'pointer',
          color: '#9452c0',
          border: '1px solid #9452c0'
        },
      },
      bulk_item_inactive: {
        width: '120px',
        margin: '0px 0px 10px 10px',
        textAlign: 'center',
        height: '58px',
        paddingTop: '5px',
        color: '#97999c'
      },
      bulk_item_no_icon: {
        width: '120px',
        margin: '0px 0px 10px 10px',
        textAlign: 'center',
        height: '53px',
        paddingTop: '10px',
        verticalAlign: 'center',
        color: '#555e68',
        '&:hover': {
          cursor: 'pointer',
          color: '#9452c0',
          border: '1px solid #9452c0'
        },
      },
      bulk_item_inactive_no_icon: {
        width: '120px',
        margin: '0px 0px 10px 10px',
        textAlign: 'center',
        height: '53px',
        paddingTop: '10px',
        color: '#97999c'
      },
      bulk_icon: {
        color: '#000'
      },
      bulk_icon_inactive: {
        color: '#97999c'
      },
      add_product_link: {
        color: '#1976d2',
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline'
        },
      },
    }),
  );


  //get the url parameters for initial filtering
  const queryParams = new URLSearchParams(window.location.search);
  const variant_filter = queryParams.get('variant') ?? '';
  const brand_filter = queryParams.get('brand') ?? '';
  const type_filter = queryParams.get('type') ?? '';
  const color_filter = queryParams.get('color') ?? '';
  const size_filter = queryParams.get('size') ?? '';
  const ean_filter = queryParams.get('ean') ?? '';
  const title_website_filter = queryParams.get('title_website') ?? '';
  const stock_filter = queryParams.get('stock') ?? '';

  const defaultFilterValue = []

  if(ean_filter) {
    defaultFilterValue.push({ name: 'ean', operator: 'eq', type: 'string', value: ean_filter })
  }

  if(title_website_filter) {
    defaultFilterValue.push({ name: 'title_website', operator: 'contains', type: 'string', value: title_website_filter })
  }

  if(variant_filter) {
    defaultFilterValue.push({ name: 'variant', operator: 'eq', type: 'string', value: variant_filter })
  }

  if(brand_filter) {
    defaultFilterValue.push({ name: 'brand', operator: 'eq', type: 'string', value: brand_filter })
  }

  if(color_filter) {
    defaultFilterValue.push({ name: 'color', operator: 'eq', type: 'string', value: color_filter })
  }

  if(size_filter) {
    defaultFilterValue.push({ name: 'size', operator: 'eq', type: 'string', value: size_filter })
  }

  if(stock_filter == 'In_stock') {
    defaultFilterValue.push({ name: 'stock', operator: 'neq', type: 'number', value: 0 })
  }

  if(stock_filter == 'Out_of_stock') {
    defaultFilterValue.push({ name: 'stock', operator: 'eq', type: 'number', value: 0 })
  }

  const classes = useStyles();


  const ColorButton = styled(Button)(({ theme }) => ({
    color: '#555e68',
    backgroundColor: 'transparent',
    fontSize: '14px',
    textTransform: 'none',
    justifyContent: 'flex-start',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  }));

  const VariantButton = styled(Button)(({ theme }) => ({
    color: '#555e68',
    marginTop: '5px',
    backgroundColor: '#e1e1e1',
    fontSize: '12px',
    textTransform: 'none',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    padding: '5px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      backgroundColor: '#e1e1e1',
    },
  }));


  const ExportButton = styled(Button)(({ theme }) => ({
    color: '#9452c0',
    fontSize: '15px',
    textTransform: 'none',
    minWidth: '100px',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#faf1ff',
    },
  }));

  const defaultSortInfo = { name: 'title', dir: 1 }

  const defaultColumns = [
    { name: 'completeness',             header: 'Status',	defaultWidth: 20, cellProps: {style: { textAlign: 'center'}}, render: ({ value, data }) => {return value === 'Complete' ? <FiberManualRecordIcon style={{color: '#7fcc21', fontSize: '12px'}} /> : <FiberManualRecordIcon style={{color: '#ff8000', fontSize: '12px'}} />}},
    { name: 'id',						            header: 'Id', editable: false, defaultWidth: 80, defaultVisible: false },
    { name: 'ean',                      header: 'EAN', editable: false, defaultWidth: 160, render: ({ value, data }) => {return value !== '' ? <div style={{ display: 'inline-block' }}><ColorButton className={classes.button_name} onClick={() => {handleOnClick(data.id)}}>{value}</ColorButton></div> : '' }},
    { name: 'main_photo',						    header: 'Image', editable: false,	defaultWidth: 85, cellProps: {style: { padding: '0px', cursor: 'pointer'}}, render: ({ value, data }) => {return (<div style={{width: 'auto', height: '60px'}}><img onClick={() => {handleOnClick(data.id)}} src={value} style={{width: '100%', height: '100%', objectFit: 'contain'}} /></div>) }},
    { name: 'article_number',           header: 'Article Number', editable: false, defaultVisible: false, defaultFlex: 1 },
    { name: 'title_website',            header: 'Title', editable: false,	defaultWidth: 450, render: ({ value, data }) => {return value !== '' ? <div style={{ display: 'inline-block' }}>{value}<br />{data.number_of_variants > 1 && <VariantButton className={classes.button_name} onClick={() => {handleOnClickVariants(data.variant)}}>{data.number_of_variants + ' variants'}</VariantButton>}</div> : '' }},
    { name: 'brand',                    header: 'Brand',	defaultWidth: 150 },
    { name: 'stock',                    header: 'Stock', editable: false, type: 'number', defaultFlex: 1 },
    { name: 'price_website_AAS',        header: 'Price', editable: true, type: 'number', defaultFlex: 1},
    { name: 'price_decathlon',          header: 'Price Decathlon', editable: true, type: 'number', defaultFlex: 1},
    { name: 'discount_active',          header: 'Discount', defaultVisible: false, editable: false, defaultFlex: 1, render: ({ value }) => {return value === 1 ? <CheckIcon style={{color: '#00bfa3'}}  /> : '' }},
    { name: 'price_discount',           header: 'Discount Price', defaultVisible: false , editable: false, type: 'number', defaultFlex: 1},
    { name: 'size',                     header: 'Size',	defaultWidth: 100 },
    { name: 'color',                    header: 'Color',	defaultWidth: 100 },
    { name: 'weight',                   header: 'Weight', type: 'number', defaultFlex: 1},
    { name: 'length',                   header: 'Lenght', type: 'number', defaultFlex: 1 },
    { name: 'width',                    header: 'Width', type: 'number', defaultFlex: 1 },
    { name: 'height',                   header: 'Height', type: 'number', defaultFlex: 1 },
    { name: 'variant_id',               header: 'Variant', editable: false,	defaultWidth: 100 , render: ({ value, data }) => {return data.variant_count_products > 1 ? <div style={{ display: 'inline-block' }}><VariantButton style={{padding: '5px', borderRadius: '5px'}} onClick={() => {window.location = 'http://localhost:3000/?variant_id=' + value}}>{data.variant_count_products + ' varianten'}</VariantButton></div> : '' }, defaultVisible: false}
  ];

  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  const [dataSource, setDataSource] = useState([]);
  const [columns] = useState(defaultColumns);


  const [selected, setSelected] = useState({});

  const [selectionActive, setSelectionActive] = useState(false);

  const onSelectionChange = useCallback(({ selected }) => {
      setSelected(selected)
      setSelectionActive(toArray(selected).length > 0)
  }, [])


    //Popover
  const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;
    //End Popover


  const loadData = () => {
    const newDataSource = () => {
      const limit = 50;
      const requestOptions = {
        method: 'GET',
        headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };
      return fetch('/api/get_products' + '?filterBy='+JSON.stringify(filterValue), requestOptions)
        .then(response => {
          const totalCount = response.headers.get('X-Total-Count');
          return response.json().then(data => {
            return data;
          })
        });
      }

    setDataSource(newDataSource)
  }


  const help_function = ({value, columnId, rowId}) => {

     onEditComplete({value, columnId, rowId})
     //----------------------------------
     ///New data source
     search_click()
  }

  const clear_filters = () => {
    setEan_select('')
    setTitle_website_select('')
    setVariant_select('')
    setBrand_select('')
    setSize_select('')
    setColor_select('')
    setStock_select('')
  }

  const [ean_filter_value, setEan_filter_value] = useState("");


  const search_click = () => {
    navigate("/Products?ean=" + ean_select + '&title_website=' + title_website_select + '&brand=' + brand_select + '&size=' + size_select + '&color=' + color_select + '&stock=' + stock_select + '&variant=' + variant_select);
    const newFilterValue = []

    if(ean_select) {
      newFilterValue.push({ name: 'ean', operator: 'eq', type: 'string', value: ean_select })
    }

    if(title_website_select) {
      newFilterValue.push({ name: 'title_website', operator: 'contains', type: 'string', value: title_website_select })
    }

    if(variant_select) {
      newFilterValue.push({ name: 'variant', operator: 'eq', type: 'string', value: variant_select })
    }

    if(brand_select) {
      newFilterValue.push({ name: 'brand', operator: 'eq', type: 'string', value: brand_select })
    }

    if(color_select) {
      newFilterValue.push({ name: 'color', operator: 'eq', type: 'string', value: color_select })
    }

    if(size_select) {
      newFilterValue.push({ name: 'size', operator: 'eq', type: 'string', value: size_select })
    }

    if(stock_select == 'In_stock') {
      newFilterValue.push({ name: 'stock', operator: 'neq', type: 'number', value: 0 })
    }

    if(stock_select == 'Out_of_stock') {
      newFilterValue.push({ name: 'stock', operator: 'eq', type: 'number', value: 0 })
    }

    const requestOptions = {
      method: 'GET',
      headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };

    const newDataSource = () => {
      return fetch('/api/get_products' + '?filterBy='+JSON.stringify(newFilterValue), requestOptions)
        .then(response => {
          const totalCount = response.headers.get('X-Total-Count');
          return response.json().then(data => {
            return data;
          })
        });
      }
    setDataSource(newDataSource)
    console.log('Refreshed?')
    //window.location.replace("http://localhost:3000/?ean=" + ean_select + '&brand=' + brand_select + '&size=' + size_select + '&color=' + color_select + '&stock=' + stock_select);
  }


  const onEditComplete = useCallback(({ value, columnId, rowId }) => {

    console.log('Update row ' + rowId + ' , column ' + columnId + ' with value ' + value)

    const requestOptions = {
      method: 'GET',
      headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };

    console.log('/api/update_attribute?value=' + value + '&attribute=' + columnId + '&ean_list=' + rowId);
    const response =  fetch('/api/update_attribute?value=' + value + '&attribute=' + columnId + '&ean_list=' + rowId, requestOptions);

    console.log(response);

    //----------------------------------
    ///New data source
    search_click()
    //---------------------------------

    console.log('Refreshed?')

  }, [dataSource])

  useEffect(() => {
    loadData();
  }, [])

  const [ean_select, setEan_select] = React.useState(ean_filter);
  const [title_website_select, setTitle_website_select] = React.useState(title_website_filter);
  const [variant_select, setVariant_select] = React.useState(variant_filter);
  const [brand_select, setBrand_select] = React.useState(brand_filter);
  const [size_select, setSize_select] = React.useState(size_filter);
  const [color_select, setColor_select] = React.useState(color_filter);
  const [stock_select, setStock_select] = React.useState(stock_filter);

  const handleChange_eanfilter = (event: SelectChangeEvent) => {
    setEan_select(event.target.value);
  };
  const handleChange_title_websitefilter = (event: SelectChangeEvent) => {
    setTitle_website_select(event.target.value);
  };
  const handleChange_brandfilter = (event: SelectChangeEvent) => {
    setBrand_select(event.target.value);
  };
  const handleChange_sizefilter = (event: SelectChangeEvent) => {
    setSize_select(event.target.value);
  };
  const handleChange_colorfilter = (event: SelectChangeEvent) => {
    setColor_select(event.target.value);
  };
  const handleChange_stockfilter = (event: SelectChangeEvent) => {
    setStock_select(event.target.value);
  };


  //-----------------------------------------------
  //Load pre filled drop down boxes
  const [all_brands, setAll_brands] = useState([]);
  const [all_sizes, setAll_sizes] = useState([]);
  const [all_colors, setAll_colors] = useState([]);

  const requestOptions = {
    method: 'GET',
    headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
  };

  useEffect(() => {
    const getBrandList = async () => {
      const res = await fetch("/api/get_distinct_attribute_values?attribute=brand", requestOptions);
      //console.log(res);
      const response = await res.json();
      setAll_brands(response);
    };
    getBrandList();
  }, []);

  useEffect(() => {
    const getSizeList = async () => {
      const res = await fetch("/api/get_distinct_attribute_values?attribute=size", requestOptions);
      //console.log(res);
      const response = await res.json();
      setAll_sizes(response);
    };
    getSizeList();
  }, []);

  useEffect(() => {
    const getColorList = async () => {
      const res = await fetch("/api/get_distinct_attribute_values?attribute=color", requestOptions);
      //console.log(res);
      const response = await res.json();
      setAll_colors(response);
    };
    getColorList();
  }, []);
  //-----------------------------------------------

  const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;

    const header = columns.map((c) => c.name).join(SEPARATOR);
    const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(SEPARATOR));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob);
  };


  const [openTag, setOpenTag] = React.useState(false);
  const [myTags,setMyTags] = React.useState("");

  const [openVariant, setOpenVariant] = React.useState(false);
  const [myVariantName,setMyVariantName] = React.useState("");

  const [openRemoveVariant, setOpenRemoveVariant] = React.useState(false);

  const [openAddProduct, setOpenAddProduct] = React.useState(false);
  const [newEan, setNewEan] = React.useState("");

  const handleClickOpenTag = () => {
    handleClosePopover();
    setOpenTag(true);
  };

  const handleCloseTag = () => {
    setOpenTag(false);
  };

  const handleClickOpenVariant = () => {
    handleClosePopover();

    selectionActive && setOpenVariant(true);
  };

  const handleCloseVariant = () => {
    setOpenVariant(false);
  };


  const handleClickRemoveVariant = () => {
    handleClosePopover();

    selectionActive && setOpenRemoveVariant(true);
  };

  const handleCloseRemoveVariant = () => {
    setOpenRemoveVariant(false);
  };


  const handleOpenAddProduct = () => {
    setOpenAddProduct(true);
  };

  const handleCloseAddProduct = () => {
    setOpenAddProduct(false);
  };

  const handleAddVariant = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

    var my_ean_string = ""
    const my_eans = toArray(selected)

    var i;
    for(i=0; i < my_eans.length; i++){
      my_ean_string = my_ean_string + my_eans[i] + ","
    }

    var raw = '{"eans":"' + my_ean_string.slice(0, -1) + '",';

    raw = raw + '"variant_title":"' + myVariantName + '"}';

    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("/api/group_variants", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));


    setMyVariantName("");
    setOpenVariant(false);

    search_click()

    console.log('Refreshed?')

  }

  const handleRemoveVariant = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

    var my_ean_string = ""
    const my_eans = toArray(selected)

    var i;
    for(i=0; i < my_eans.length; i++){
      my_ean_string = my_ean_string + my_eans[i] + ","
    }

    var raw = '{"eans":"' + my_ean_string.slice(0, -1) + '"}';

    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("/api/remove_from_variant", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

    setOpenRemoveVariant(false);

    search_click()

    console.log('Refreshed?')

  }

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleAddProduct = async () => {

    console.log(newEan)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

    var raw = '{"ean":"' + newEan + '"}';

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("/api/add_product", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

    await delay(2000);

    setOpenAddProduct(false)

    handleOnClick(newEan)

  }

  const handleAddTags = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

    const my_eans = toArray(selected)

    var i;
    for(i=0; i < my_eans.length; i++){

      var raw = '{"ean":"' + my_eans[i] + '","append_tags":"yes",';

      raw = raw + '"tags":"' + myTags + '"}';

      console.log(raw)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("/api/update_product", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

    }

    setMyTags("");
    setOpenTag(false);
  };


  const onChange = (e) => {
      const { target: { name, value } } = e;

      if(name == 'add_tags'){
        setMyTags(value);
      }
  };


  const onChangeVariant = (e) => {
      const { target: { name, value } } = e;

      if(name == 'add_variant'){
        setMyVariantName(value);
      }
  };


  const onChangeAddProduct = (e) => {
      const { target: { name, value } } = e;

      if(name == 'add_product'){
        setNewEan(value);
      }
  };


  const gridStyle = { marginTop: 10, height: '75vh' }

  const onlyContainsNumbers = (str) => /^\d+$/.test(str);

  if (isAuthenticated) {

  return (

    <div>

    <Grid container>

      <Grid item xs="auto">

        <Menu navigate={navigate} page={'products'}/>

      </Grid>

      <Grid item xs>

    <div>

    <div style={{marginTop: '55px', padding: '10px 20px 10px 20px'}}>

    <div style={{margin: '5px 0px 5px 0px', height: '60px'}}>

    <Grid container justifyContent="space-between">

    <Grid item xs={7}>

          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField id="outlined-basic" label="EAN" fullWidth variant="outlined" size="small" value={ean_select} onChange={handleChange_eanfilter} />
          </Grid>

          <Grid item xs={4}>
            <TextField id="outlined-basic" label="Title" fullWidth variant="outlined" size="small" value={title_website_select} onChange={handleChange_title_websitefilter} />
         </Grid>

          <Grid item xs={4}>
          <FormControl sx={{  minWidth: 120 }} fullWidth size="small">
                <InputLabel id="demo-select-small-label">Brand</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={brand_select}
                  label="Brand"
                  onChange={handleChange_brandfilter}
                >
                  <MenuItem value="">All brands</MenuItem>
                  {all_brands.map((attribute) => (
                    <MenuItem key={attribute.brand} value={attribute.brand}>{attribute.brand}</MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>

          <Grid item>
          <FormControl sx={{  minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Size</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={size_select}
                  label="Size"
                  onChange={handleChange_sizefilter}
                >
                  <MenuItem value="">All sizes</MenuItem>
                  {all_sizes.map((attribute) => (
                    <MenuItem key={attribute.size} value={attribute.size}>{attribute.size}</MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>

          <Grid item>
          <FormControl sx={{  minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Color</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={color_select}
                  label="Color"
                  onChange={handleChange_colorfilter}
                >
                  <MenuItem value="">All colors</MenuItem>
                  {all_colors.map((attribute) => (
                    <MenuItem key={attribute.color} value={attribute.color}>{attribute.color}</MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>

          <Grid item>
          <FormControl sx={{  minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Stock</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={stock_select}
                  label="Color"
                  onChange={handleChange_stockfilter}
                >
                  <MenuItem value="">No filter</MenuItem>
                  <MenuItem value={"In_stock"}>In stock</MenuItem>
                  <MenuItem value={"Out_of_stock"}>Out of stock</MenuItem>
                </Select>
              </FormControl>
          </Grid>

          <Grid item>
            <Button onClick={() => search_click()} style={{marginLeft: 10}}variant="contained" >Search</Button>
          </Grid>

          <Grid item>
            <Button onClick={() => clear_filters()} style={{marginLeft: 10}}variant="outlined" >Clear all</Button>
          </Grid>

          </Grid>
      </Grid>

      <Grid item xs={5}>

      <Grid container justifyContent="flex-end">

            <Grid item>
              <Card variant="outlined" className={selectionActive ? classes.bulk_item : classes.bulk_item_inactive} onClick={handleClickPopover}>
              <DataArrayIcon className={selectionActive ? classes.bulk_icon : classes.bulk_icon_inactive} />
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  Change property
                </Typography>
              </Card>
              <Popover
                  id={id}
                  open={selectionActive && openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                <div>
                    <ExportButton onClick={handleClickOpenTag}>Tags</ExportButton><br/>
                    </div>
                </Popover>

                <Dialog open={openTag} onClose={handleCloseTag}>
                  <DialogTitle>Add tags</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Add tags for <b>{toArray(selected).length + (toArray(selected).length !== 1 ? ' products' : ' product')}</b>. These tags will be added to any existing tags.
                      Seperate tags with a comma ",".
                    </DialogContentText>
                    <TextField
                      name="add_tags"
                      autoFocus
                      value={myTags}
                      margin="dense"
                      id="name"
                      fullWidth
                      variant="outlined"
                      onChange={onChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseTag}>Cancel</Button>
                    <Button onClick={handleAddTags}>Add tags</Button>
                  </DialogActions>
                </Dialog>

            </Grid>

            <Grid item>
            <Card variant="outlined" className={selectionActive ? classes.bulk_item : classes.bulk_item_inactive} onClick={handleClickOpenVariant}>
              <WorkspacesIcon className={selectionActive ? classes.bulk_icon : classes.bulk_icon_inactive} />
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                Link as variants
              </Typography>
            </Card>

            <Dialog open={openVariant} onClose={handleCloseVariant}>
              <DialogTitle>Group as variant</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Group <b>{toArray(selected).length + (toArray(selected).length !== 1 ? ' products' : ' product')}</b>.<br />
                  Choose a product title for this group to show on our website and marketplaces. (not including size / color details)
                </DialogContentText>
                <TextField
                  name="add_variant"
                  label="Product title"
                  autoFocus
                  value={myVariantName}
                  margin="dense"
                  id="name"
                  fullWidth
                  variant="outlined"
                  onChange={onChangeVariant}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseVariant}>Cancel</Button>
                <Button onClick={handleAddVariant}>Save</Button>
              </DialogActions>
            </Dialog>
            </Grid>

            <Grid item>
            <Card variant="outlined" className={selectionActive ? classes.bulk_item_no_icon : classes.bulk_item_inactive_no_icon} onClick={handleClickRemoveVariant}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                Remove from variant
              </Typography>
            </Card>

            <Dialog open={openRemoveVariant} onClose={handleCloseRemoveVariant}>
              <DialogTitle>Remove from variant</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to remove <b>{toArray(selected).length + (toArray(selected).length !== 1 ? ' products' : ' product')}</b> from their variant(s).<br />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseRemoveVariant}>Cancel</Button>
                <Button onClick={handleRemoveVariant}>Save</Button>
              </DialogActions>
            </Dialog>

            </Grid>


            <Grid item>
            <Card variant="outlined" className={classes.bulk_item} onClick={exportCSV}>
              <FileDownloadIcon className={classes.bulk_icon} />
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                Export products
              </Typography>
            </Card>
          </Grid>

        </Grid>

      </Grid>


      </Grid>

      </div>

      <div style={{margin: '50px 0px 0px 0px'}}>

      <Grid container justifyContent="space-between">

        <Grid item>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
              <b>Bulk actions</b> ({toArray(selected).length + (toArray(selected).length !== 1 ? ' products selected' : ' product selected')})
          </Typography>
        </Grid>

        <Grid>

        <Typography sx={{ fontSize: 14 }}>
            <b onClick={handleOpenAddProduct} className={classes.add_product_link}>Add product</b>
        </Typography>

        <Dialog open={openAddProduct} onClose={handleCloseAddProduct}>
          <DialogTitle>EAN of new product</DialogTitle>
          <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <TextField
              name="add_product"
              label="EAN"
              autoFocus
              value={newEan}
              margin="dense"
              id="name"
              fullWidth
              variant="outlined"
              onChange={onChangeAddProduct}
              error={newEan.length != 13 || !onlyContainsNumbers(newEan)}
              helperText="The EAN needs to have 13 digits"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddProduct}>Cancel</Button>
            <Button onClick={handleAddProduct} disabled={newEan.length == 13 & onlyContainsNumbers(newEan) ? false : true}>Save</Button>
          </DialogActions>
        </Dialog>

        </Grid>

      </Grid>

      <ReactDataGrid
          handle={setGridRef}
          idProperty="id"
          selected={selected}
          style={gridStyle}
          rowHeight={65}
          showZebraRows={false}
          onEditComplete={help_function}
          editable={true}
          columns={columns}
          emptyText={emptyText}
          pagination="local"
          //defaultFilterValue={defaultFilterValue}
          checkboxColumn
          onSelectionChange={onSelectionChange}
          dataSource={dataSource}
          defaultSortInfo={defaultSortInfo}
          enableKeyboardNavigation={false}
          //onFilterValueChange={setFilterValue}
      />

      </div>

      </div>

      </div>

      </Grid>

    </Grid>

      </div>
  )
}
else {
  return (
    <Homepage />
)
}
}

export default () => <App />
