import React, { useEffect, useState, useCallback } from 'react'
import Menu from './Menu.js';
import Homepage from './Homepage.js';
import {useNavigate} from 'react-router-dom';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAuth0 } from "@auth0/auth0-react";
import Chip from '@mui/material/Chip';

const toArray = selected => Object.keys(selected).map(id => id * 1);

const emptyText = <b style={{
  padding: 8,
  border: '1px solid #92278e',
  color: '#92278e',
  borderRadius: 4
}}>No products found</b>


const App = () => {

  const navigate = useNavigate();

  const [gridRef, setGridRef] = useState(null);

  const { isAuthenticated } = useAuth0();

  const handleOnClick = (id) => navigate('/Product?language=nl-NL&id=' + id);

  const handleOnClickVariants = (variant) => {
    const url = "/?ean=&brand=&size=&color=&stock=&variant=" + variant
    window.location.href = url
  };


  //get the url parameters for initial filtering
  const queryParams = new URLSearchParams(window.location.search);
  const brand_filter = queryParams.get('brand') ?? '';

  const defaultFilterValue = []

  if(brand_filter) {
    defaultFilterValue.push({ name: 'brand', operator: 'eq', type: 'string', value: brand_filter })
  }

  const ColorButton = styled(Button)(({ theme }) => ({
    color: '#555e68',
    backgroundColor: 'transparent',
    fontSize: '14px',
    textTransform: 'none',
    justifyContent: 'flex-start',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  }));

  const VariantButton = styled(Button)(({ theme }) => ({
    color: '#555e68',
    marginTop: '5px',
    backgroundColor: '#e1e1e1',
    fontSize: '12px',
    textTransform: 'none',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    padding: '5px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      backgroundColor: '#e1e1e1',
    },
  }));

  const [my_query_id, setMy_query_id] = useState('out_of_stock_forecast');

  const defaultSortInfo = { name: 'total_sold', dir: -1 }

  const defaultColumns = [
    { name: 'id',						            header: 'Id', editable: false, defaultWidth: 80, defaultVisible: false },
    { name: 'ean',                      header: 'EAN', editable: false, defaultWidth: 160, render: ({ value, data }) => {return value !== '' ? <div style={{ display: 'inline-block' }}><ColorButton onClick={() => {handleOnClick(data.id)}}>{value}</ColorButton></div> : '' }},
    { name: 'main_photo',						    header: 'Image', editable: false,	defaultWidth: 85, cellProps: {style: { padding: '0px', cursor: 'pointer'}}, render: ({ value, data }) => {return (<div style={{width: 'auto', height: '60px'}}><img alt="" onClick={() => {handleOnClick(data.id)}} src={value} style={{width: '100%', height: '100%', objectFit: 'contain'}} /></div>) }},
    { name: 'article_number',           header: 'Article Number', editable: false, defaultVisible: false, defaultFlex: 1 },
    { name: 'title_website',            header: 'Title', editable: false,	defaultWidth: 450, render: ({ value, data }) => {return value !== '' ? <div style={{ display: 'inline-block' }}>{value}<br />{data.number_of_variants > 1 && <VariantButton onClick={() => {handleOnClickVariants(data.variant)}}>{data.number_of_variants + ' variants'}</VariantButton>}</div> : '' }},
    { name: 'brand',                    header: 'Brand',	defaultWidth: 120 },
    { name: 'size',                     header: 'Size',	defaultWidth: 100 },
    { name: 'color',                    header: 'Color',	defaultWidth: 100 },
    { name: 'price_website_AAS',        header: 'Price', editable: false, type: 'number', defaultFlex: 1},
    { name: 'price_decathlon',          header: 'Price Decathlon', editable: false, type: 'number', defaultFlex: 1},
    { name: 'variant_id',               header: 'Variant', editable: false,	defaultWidth: 100 , render: ({ value, data }) => {return data.variant_count_products > 1 ? <div style={{ display: 'inline-block' }}><VariantButton style={{padding: '5px', borderRadius: '5px'}} onClick={() => {window.location = 'http://localhost:3000/?variant_id=' + value}}>{data.variant_count_products + ' varianten'}</VariantButton></div> : '' }, defaultVisible: false},
    { name: 'Sold_out_in_months',       header: 'Sold Out', sortable: false, defaultWidth: 140, render: ({ value, data }) => {return value !== '' && data.critical ? <div style={{ display: 'inline-block' }}><Chip label={value} color={data.critical} size="small" /></div> : '' } },
    { name: 'stock',                    header: 'Stock', editable: false, type: 'number',	defaultWidth: 100  },
    { name: 'variant_stock',            header: 'Stock all variants', editable: false, type: 'number',	defaultWidth: 100, defaultVisible: false },
    { name: 'total_sold',               header: 'Total sold', editable: false, type: 'number',	defaultWidth: 100  },
    { name: 'Average_per_month',        header: 'Month Average', editable: false, type: 'number',	defaultWidth: 100 },
  ];

  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  const [dataSource, setDataSource] = useState([]);
  const [columns] = useState(defaultColumns);


  const [selected, setSelected] = useState({});

  const [selectionActive, setSelectionActive] = useState(false);

  const onSelectionChange = useCallback(({ selected }) => {
      setSelected(selected)
      setSelectionActive(toArray(selected).length > 0)
  }, [])


  const loadData = () => {
    const newDataSource = () => {

      const requestOptions = {
        method: 'GET',
        headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };
      return fetch('/api/get_analytics_result' + '?query_type='+my_query_id+'&filterBy='+JSON.stringify(filterValue), requestOptions)
        .then(response => {
          return response.json().then(data => {
            return data;
          })
        });
      }

    setDataSource(newDataSource)
  }


  const help_function = ({value, columnId, rowId}) => {

     onEditComplete({value, columnId, rowId})
     //----------------------------------
     ///New data source
     search_click()
  }

  const clear_filters = () => {
    setBrand_select('')
  }

  const search_click = () => {
    navigate("/analytics?brand=" + brand_select);
    const newFilterValue = []

    if(brand_select) {
      newFilterValue.push({ name: 'brand', operator: 'eq', type: 'string', value: brand_select })
    }

    const requestOptions = {
      method: 'GET',
      headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };

    const newDataSource = () => {
      return fetch('/api/get_analytics_result' + '?query_type='+my_query_id+'&filterBy='+JSON.stringify(newFilterValue), requestOptions)
        .then(response => {
          return response.json().then(data => {
            return data;
          })
        });
      }
    setDataSource(newDataSource)
    console.log('Refreshed?')
    //window.location.replace("http://localhost:3000/?ean=" + ean_select + '&brand=' + brand_select + '&size=' + size_select + '&color=' + color_select + '&stock=' + stock_select);
  }


  const onEditComplete = useCallback(({ value, columnId, rowId }) => {

    console.log('Update row ' + rowId + ' , column ' + columnId + ' with value ' + value)

    const requestOptions = {
      method: 'GET',
      headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };

    console.log('/api/update_attribute?value=' + value + '&attribute=' + columnId + '&ean_list=' + rowId);
    const response =  fetch('/api/update_attribute?value=' + value + '&attribute=' + columnId + '&ean_list=' + rowId, requestOptions);

    console.log(response);

    //----------------------------------
    ///New data source
    search_click()
    //---------------------------------

    console.log('Refreshed?')

  }, [dataSource])

  useEffect(() => {
    loadData();
  }, [])

  const [brand_select, setBrand_select] = React.useState(brand_filter);

  const handleChange_brandfilter = (event: SelectChangeEvent) => {
    setBrand_select(event.target.value);
  };
  const handleChangeAnalytic = (event: SelectChangeEvent) => {
    setMy_query_id(event.target.value);
  };

  //-----------------------------------------------
  //Load pre filled drop down boxes
  const [all_brands, setAll_brands] = useState([]);

  const requestOptions = {
    method: 'GET',
    headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
  };

  useEffect(() => {
    const getBrandList = async () => {
      const res = await fetch("/api/get_distinct_attribute_values?attribute=brand", requestOptions);
      //console.log(res);
      const response = await res.json();
      setAll_brands(response);
    };
    getBrandList();
  }, []);


  //-----------------------------------------------

  const gridStyle = { marginTop: 10, height: '78vh' }

  if (isAuthenticated) {

  return (

    <div>

    <Grid container>

      <Grid item xs="auto">

        <Menu navigate={navigate} page={'analytics'}/>

      </Grid>

      <Grid item xs>

    <div>

    <div style={{marginTop: '55px', padding: '10px 20px 10px 20px'}}>

    <div style={{margin: '5px 0px 5px 0px', height: '60px'}}>

    <Grid container>

    <Grid item xs={12}>

          <Grid container spacing={1}>

          <Grid item xs={4}>
          <Select
            labelId="analytic"
            id="analytic"
            value={my_query_id}
            label="Analytic"
            onChange={handleChangeAnalytic}
            fullWidth
            size="small"
          >
            <MenuItem value={'out_of_stock_forecast'}>Out of stock forecast (5 months average)</MenuItem>
            <MenuItem value={'best_sold_products'}>Best sold products (last 12 months)</MenuItem>
            <MenuItem value={'out_of_stock_6_months'}>Out of stock products sold last 6 months</MenuItem>
            <MenuItem value={'out_of_stock_12_months'}>Out of stock products sold last 12 months</MenuItem>
            <MenuItem value={'products_not_sold'}>Products not sold (last 12 months)</MenuItem>
          </Select>
          </Grid>


          <Grid item xs={2}>
          <FormControl sx={{  minWidth: 120 }} fullWidth size="small">
                <InputLabel id="demo-select-small-label">Brand</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={brand_select}
                  label="Brand"
                  onChange={handleChange_brandfilter}
                  defaultValue = ""
                >
                  <MenuItem value="">All brands</MenuItem>
                  {all_brands.map((attribute) => (
                    <MenuItem key={attribute.brand} value={attribute.brand}>{attribute.brand}</MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>

          <Grid item>
            <Button onClick={() => search_click()} style={{marginLeft: 10}}variant="contained" >Show results</Button>
          </Grid>

          <Grid item>
            <Button onClick={() => clear_filters()} style={{marginLeft: 10}}variant="outlined" >Clear all</Button>
          </Grid>

          </Grid>
      </Grid>

      <Grid item xs={5}>


      </Grid>


      </Grid>

      </div>

      <div style={{margin: '20px 0px 0px 0px'}}>

      <ReactDataGrid
          handle={setGridRef}
          idProperty="id"
          selected={selected}
          style={gridStyle}
          rowHeight={65}
          showZebraRows={false}
          onEditComplete={help_function}
          editable={false}
          columns={columns}
          emptyText={emptyText}
          pagination="local"
          //defaultFilterValue={defaultFilterValue}
          checkboxColumn
          onSelectionChange={onSelectionChange}
          dataSource={dataSource}
          defaultSortInfo={defaultSortInfo}
      />

      </div>

      </div>

      </div>

      </Grid>

    </Grid>

      </div>
  )
}
else {
  return (
      <Homepage />
)
}
}

export default () => <App />
