import React, { useEffect, useState } from "react";
import Menu from './Menu.js';
import Homepage from './Homepage.js';
import {useNavigate} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    ochre: {
      main: '#FFF',
      light: '#FCFCFC',
      dark: '#F1F1F1',
      contrastText: '#000',
    },
  },
});

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
'Accessoires'
,'American Football'
,'American Football Ballen'
,'American Football Handschoenen'
,'American Football Kleding'
,'American Football Bescherming'
,'Ballen'
,'Baseball Caps'
,'Basketbal'
,'Basketbal Ballen'
,'Basketbal Kleding'
,'Dames'
,'Dames Accessoires'
,'Dames Kleding'
,'Dames Schoenen'
,'Dames Shorts'
,'Dames Sokken'
,'Dames T-shirts'
,'Fan artikelen'
,'Golf'
,'Heren'
,'Heren Accessoires'
,'Heren Kleding'
,'Heren Schoenen'
,'Heren Shorts'
,'Heren T-Shirts'
,'Honkbal'
,'Honkbal Ballen'
,'Honkbal Bescherming'
,'Honkbal Tassen'
,'Honkbal Trainingsmateriaal'
,'Honkbalhandschoenen'
,'Honkbalknuppels'
,'Honkbalschoenen'
,'Hoofdbanden'
,'Hoofdbedekking'
,'Kleding Honkbal'
,'Kleding Softbal'
,'Outdoor / Hiking'
,'Outdoor accessoires'
,'Outdoor kleding'
,'Polsbanden'
,'Slaghandschoentjes'
,'Softbal'
,'Softbal Ballen'
,'Softbal Bescherming'
,'Softbalhandschoenen'
,'Softbalknuppels'
,'Sokken'
,'Turnen'
,'Turnen Accessoires'
,'Turnkleding'
,'Turnleertjes'
,'Volleybal'
,'Zonnebrillen'
];


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      backgroundColor: '#eee',
    },
    full_root: {
      backgroundColor: '#F4F4F4'
    },
    root: {
      flexGrow: 1,
      margin: 'auto',
      maxWidth: 1200,
      paddingTop: '55px'
    },
    name: {
      fontFamily: 'Arial',
      fontWeight: 600,
      fontSize: 30,
    },
    paper: {
      padding: theme.spacing(2),
      margin: '0 auto 50px auto',
      maxWidth: 700,
    },
    paper_custom: {
      padding: '15px',
      margin: '15px',
    },
    title: {
      fontWeigh: 'bold',
      color: '#9452c0'
    },
    head_text: {
      margin: 'auto',
      marginTop: '20px',
      marginBottom: '3px',
      fontSize: '13px'
    },
    head_top_text: {
      margin: 'auto',
      fontSize: '13px'
    }
  }),
);



const Product = (props) => {

  const { isAuthenticated } = useAuth0();

  const [saveDisabled, setSaveDisabled] = React.useState(true);

  const queryParams = new URLSearchParams(window.location.search);
  const my_product_id = queryParams.get('id');

  useEffect(() => {
          const url = '/api/get_product' + '?ean=' + my_product_id;

          const requestOptions = {
            method: 'GET',
            headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
          };

          const fetchData = async () => {
              try {
                  const response = await fetch(url, requestOptions);
                  const json = await response.json();

                  setNumber_of_variants(json[0]['number_of_variants'])

                  setTitle_website(json[0]['title_website'])
                  setTitle_variant(json[0]['variant_title'])
                  setDescription(json[0]['description'])
                  setTags(json[0]['tags'])

                  if(json[0]['tags']){
                    setAllTags(json[0]['tags'].split(","))
                  }else {
                    setAllTags([])
                  }

                  setPrice(json[0]['price_website_AAS'])
                  setStock(json[0]['stock'])
                  setEan(json[0]['ean'])
                  setVariant(json[0]['variant'])
                  setBrand(json[0]['brand'])
                  setColor(json[0]['color'])
                  setSize(json[0]['size'])
                  setWeight(json[0]['weight'])
                  setLength(json[0]['length'])
                  setWidth(json[0]['width'])
                  setHeight(json[0]['height'])
                  setProduct_type(json[0]['product_type'])
                  setMain_photo(json[0]['main_photo'])

                  setSold_Trend([json[0]['a'], json[0]['b'], json[0]['c'], json[0]['d'], json[0]['e']])

             } catch (error) {
                 console.log("error", error);
             }
         };

         fetchData();
     }, []);


    const classes = useStyles();
    const navigate = useNavigate();

    const [number_of_variants, setNumber_of_variants] = useState("");
    const [title_website, setTitle_website] = useState("");
    const [title_website_changed, setTitle_website_changed] = useState(false);
    const [title_variant, setTitle_variant] = useState("");
    const [title_variant_changed, setTitle_variant_changed] = useState(false);
    const [description, setDescription] = useState("");
    const [description_changed, setDescription_changed] = useState(false);
    const [tags, setTags] = useState("");
    const [tags_changed, setTags_changed] = useState(false);
    const [price, setPrice] = useState("");
    const [stock, setStock] = useState("");
    const [ean, setEan] = useState("");
    const [variant, setVariant] = useState("");
    const [brand, setBrand] = useState("");
    const [brand_changed, setBrand_changed] = useState(false);
    const [color, setColor] = useState("");
    const [color_changed, setColor_changed] = useState(false);
    const [size, setSize] = useState("");
    const [size_changed, setSize_changed] = useState(false);
    const [weight, setWeight] = useState("");
    const [weight_changed, setWeight_changed] = useState(false);
    const [length, setLength] = useState("");
    const [length_changed, setLength_changed] = useState(false);
    const [width, setWidth] = useState("");
    const [width_changed, setWidth_changed] = useState(false);
    const [height, setHeight] = useState("");
    const [height_changed, setHeight_changed] = useState(false);
    const [product_type, setProduct_type] = useState("");
    const [product_type_changed, setProduct_type_changed] = useState(false);
    const [main_photo, setMain_photo] = useState("");
    const [main_photo_changed, setMain_photo_changed] = useState(false);
    const [sold_Trend, setSold_Trend] = useState("");

    const onChange = (e) => {
        const { target: { name, value } } = e;

        if(name === 'title_website'){
          setTitle_website_changed(true);
          setTitle_website(value);
        }
        else if(name === 'title_variant'){
          setTitle_variant(value);
            setTitle_variant_changed(true);
        }
        else if(name === 'description'){
          setDescription(value);
            setDescription_changed(true);
        }
        else if(name === 'tags'){
          setTags(value);
            setTags_changed(true);
        }
        else if(name === 'brand'){
          setBrand(value);
            setBrand_changed(true);
        }
        else if(name === 'color'){
          setColor(value);
            setColor_changed(true);
        }
        else if(name === 'size'){
          setSize(value);
            setSize_changed(true);
        }
        else if(name === 'weight'){
          setWeight(value);
            setWeight_changed(true);
        }
        else if(name === 'length'){
          setLength(value);
            setLength_changed(true);
        }
        else if(name === 'width'){
          setWidth(value);
            setWidth_changed(true);
        }
        else if(name === 'height'){
          setHeight(value);
            setHeight_changed(true);
        }
        else if(name === 'product_type'){
          setProduct_type(value);
            setProduct_type_changed(true);
        }
        else if(name === 'main_photo'){
          setMain_photo(value);
            setMain_photo_changed(true);
        }

        setSaveDisabled(false);
      };

      const submitChange = () => {

        setSaveDisabled(true);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

        var raw = '{"ean":"' + ean + '","variant":"' + variant + '","append_tags":"no",';

        if(title_website_changed){
          raw = raw + '"title_website":"' + title_website + '",';
        }

        if(description_changed){
          raw = raw + '"description":' + JSON.stringify(description.replace(/(?:\r\n|\r|\n)/g, '\\n')) + ',';
        }

        if(title_variant_changed){
          raw = raw + '"variant_title":"' + title_variant + '",';
        }

        if(tags_changed){
          raw = raw + '"tags":"' + tags + '",';
        }

        if(brand_changed){
          raw = raw + '"brand":"' + brand + '",';
        }

        if(color_changed){
          raw = raw + '"color":"' + color + '",';
        }

        if(size_changed){
          raw = raw + '"size":"' + size + '",';
        }

        if(weight_changed){
          raw = raw + '"weight":"' + weight + '",';
        }

        if(length_changed){
          raw = raw + '"length":"' + length + '",';
        }

        if(width_changed){
          raw = raw + '"width":"' + width + '",';
        }

        if(height_changed){
          raw = raw + '"height":"' + height + '",';
        }

        if(product_type_changed){
          raw = raw + '"product_type":"' + product_type + '",';
        }

        if(main_photo_changed){
          raw = raw + '"main_photo":"' + main_photo + '",';
        }

        raw = raw.slice(0, -1) + '}';

        console.log(raw)

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch("/api/update_product", requestOptions)
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
      }


      const parseLines = (value) => value.replace(/(\\n)/g, "\n");

      const [allTags, setAllTags] = React.useState([]);

      const handleChange = (event) => {

        const {
          target: { value },
        } = event;
        setAllTags(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
        setTags(value.toString());
        setTags_changed(true);
        setSaveDisabled(false);
      };



      const [selectedFile, setSelectedFile] = useState();
    	const [isFilePicked, setIsFilePicked] = useState(false);

    	const changeHandler = (event) => {
        console.log(event.target.files)

        var my_photos = main_photo

        var i = 0;
        for (const my_file of event.target.files) {

      		setSelectedFile(my_file);
      		setIsFilePicked(true);

          console.log('Start save file')

          const formData = new FormData();

      		formData.append('File', my_file);
          formData.append('ean', ean);
          formData.append('type', 'image_fullsize');

      		fetch(
      			'/api/file_upload',
      			{
      				method: 'POST',
      				body: formData,
      			}
      		)
          .then(response => response.text())
          .then(result => {
            console.log(result);
            console.log('test_foto');

            if(my_photos === ''){
              my_photos = result
            }else{
              my_photos = my_photos + ',' + result
            }

            if(i === event.target.files.length){
              setMain_photo(my_photos)
            }

          }
          )
          .catch(error => console.log('error', error));

          i = i + 1

        }


    	};

      const deleteFile = () => {

        setOpenDeleteFile(false);

        var my_photos = main_photo.replace(fileToDelete, '').replace(',,', ',')
        if (my_photos.slice(-1) === ',') {
          my_photos = my_photos.slice(0, -1)
        }

        if (my_photos.slice(0,1) === ',') {
          my_photos = my_photos.slice(1)
        }

        setMain_photo(my_photos)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

        var raw = '{"file_name":"' + fileToDelete + '"}';

        console.log(raw)

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch("/api/delete_file", requestOptions)
         .then(response => response.text())
         .then(result => console.log(result))
         .catch(error => console.log('error', error));

      }


      const [openPicture, setOpenPicture] = React.useState(false);
      const [showPicture, setShowPicture] = React.useState();
      const [openDeleteFile, setOpenDeleteFile] = React.useState(false);
      const [fileToDelete, setFileToDelete] = React.useState('');

      const handleClickOpenDeleteFile = (photo) => {
        setFileToDelete(photo);
        setOpenDeleteFile(true);
      };

      const handleCloseDeleteFile = () => {
        setOpenDeleteFile(false);
      };


      const handleMoveFileOrder = (photo, direction) => {

        var new_photo_array = main_photo.split(',');

        console.log("Before move: " + new_photo_array);

        var index_from = 0

        for (var j=0; j<new_photo_array.length; j++) {
          if(new_photo_array[j].match(photo)){
            index_from = j;
            break;
          }
        }
        console.log(index_from)

        if(direction === 'up'){
          new_photo_array[index_from] = new_photo_array[index_from - 1];
          new_photo_array[index_from - 1] = photo;
        }
        else{
          new_photo_array[index_from] = new_photo_array[index_from + 1];
          new_photo_array[index_from + 1] = photo;
        }

        setMain_photo(new_photo_array.toString())

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

        var raw = '{"ean":"' + ean + '", "file_name":"' + photo + '", "direction":"' + direction + '"}';

        console.log(raw)

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch("/api/change_file_order", requestOptions)
         .then(response => response.text())
         .then(result => console.log(result))
         .catch(error => console.log('error', error));

      }


      const handleClickOpenPicture = (photo) => {
        setShowPicture(photo)
        setOpenPicture(true);
      };

      const handleClosePicture = () => {
        setOpenPicture(false);
      };


      const my_months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      const this_month = new Date().getMonth();

  if (isAuthenticated) {

  return (

    <div className={classes.full_root}>

    <Grid container>

      <Grid item xs="auto">

        <Menu navigate={navigate} page={'products'}/>

      </Grid>

    <Grid item xs>

    <div>

    <div style={{ height: '30px', width: '100%', position: 'fixed', zIndex: '100', borderBottom: '1px solid #d0d7de', backgroundColor: '#fff', padding: '10px 10px 10px 10px'}}>

      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{ textTransform: 'none', marginRight: '20px' }}
        startIcon={<ArrowBackIosIcon />}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
        <Button
          variant="contained"
          size="small"
          disabled={saveDisabled}
          style={{ textTransform: 'none' }}
          startIcon={<SaveIcon />}
          onClick={submitChange}
        >
          Save changes
        </Button>

    </div>

  {ean !== "" &&
      <div className={classes.root}>

        <Grid container justifyContent="center" >

        <Grid item xs={8}>

                <Paper className={classes.paper_custom}>

                <Typography variant="body1" className={classes.head_top_text}>
                  <b>Variant properties ({number_of_variants} {number_of_variants > 1 ? 'variants' : 'variant'})</b>
                </Typography>

                  <Typography variant="body1" className={classes.head_text}>
                    Collections
                  </Typography>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    name="tags"
                    multiple
                    fullWidth
                    size="small"
                    value={allTags}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} size="small" color="secondary" />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {names.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        size="small"
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>

                  <Grid container justifyContent="center" >

                    <Grid item xs={6}>
                        <Typography variant="body1" className={classes.head_text}>
                          Brand
                        </Typography>
                        <div>
                          <TextField
                            name="brand"
                            id="outlined-size-small"
                            value={brand}
                            size="small"
                            InputProps={{ style: { fontSize: 14 } }}
                            onChange={onChange}
                          />
                        </div>
                        </Grid>

                        <Grid item xs={6}>
                        <Typography variant="body1" className={classes.head_text}>
                          Product Type
                        </Typography>
                        <div>
                          <TextField
                            name="product_type"
                            id="outlined-size-small"
                            value={product_type}
                            size="small"
                            InputProps={{ style: { fontSize: 14 } }}
                            onChange={onChange}
                          />
                          </div>
                          </Grid>
                      </Grid>

                      </Paper>

          <Paper className={classes.paper_custom}>

                  <Typography variant="body1" className={classes.head_text}>
                      Title
                    </Typography>
                    <div>
                      <TextField
                        name="title_variant"
                        id="outlined-size-small"
                        value={title_variant}
                        size="small"
                        fullWidth
                        InputProps={{ style: { fontSize: 14 } }}
                        onChange={onChange}
                      />
                    </div>


                    <Typography variant="body1" className={classes.head_text}>
                      Description
                    </Typography>
                    <div>
                      <TextField
                        name="description"
                        id="outlined-size-small"
                        value={description && parseLines(description)}
                        size="small"
                        fullWidth
                        multiline
                        rows={10}
                        InputProps={{ style: { fontSize: 14 } }}
                        onChange={onChange}
                      />
                    </div>
        </Paper>


                    <Paper className={classes.paper_custom}>

                    <Typography variant="body1" className={classes.head_top_text}>
                      <b>Images</b>
                    </Typography>

                    <p />

                    <div><Button
                        variant="outlined"
                        component="label"
                        size="small"
                        startIcon={<FileUploadIcon />}
                        style={{ textTransform: 'none', margin: '5px 20px 5px 0px' }}
                      >
                      <input hidden type="file" name="file" onChange={changeHandler} multiple />
                      Upload image(s)
                    </Button>
                    <Button
                        variant="text"
                        component="label"
                        size="small"
                        style={{ textTransform: 'none' }}
                        disabled
                      >
                      <input hidden type="file" name="file" onChange={changeHandler} />
                      Add image via URL
                    </Button>
                    </div>

                    {main_photo &&
                    <ImageList
                       sx={{
                         width: 'auto',
                         //height: 250,
                         // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                         transform: 'translateZ(0)',
                       }}

                       cols={4}
                       //rowHeight={200}
                       gap={6}
                     >
                       {main_photo.split(',').map((photo, index, row) => {

                         return (

                           <ImageListItem  key={photo} cols={1} rows={1}
                           sx={{
                             border: '1px solid #eee',
                             cursor: 'pointer'
                           }}
                           >
                             <img alt={photo} onClick={() => handleClickOpenPicture(photo)}
                               {...srcset(photo, 300, 200, 1, 1)}
                               loading="lazy"
                             />

                             <ImageListItemBar
                             sx={{
                               background: 'transparent'
                             }}
                              title={<CancelIcon sx={{ color: '#333' }} fontSize="small" onClick={() => handleClickOpenDeleteFile(photo)} />}
                               position="top"
                               actionIcon={

                                 <ButtonGroup aria-label="Vertical button group">

                                 {index > 0 &&
                                 <ThemeProvider theme={theme}><Button onClick={() => handleMoveFileOrder(photo, 'up')} key="one" size="small" variant="contained" color="ochre"><ArrowLeftIcon /></Button> </ThemeProvider>
                               }

                                 {index + 1 !== row.length &&
                                 <ThemeProvider theme={theme}><Button onClick={() => handleMoveFileOrder(photo, 'down')} key="two" size="small" variant="contained" color="ochre"><ArrowRightIcon /></Button></ThemeProvider>
                               }
                                </ButtonGroup>
                               }
                               actionPosition="right"
                             />

                           </ImageListItem>
                         );
                       })}
                     </ImageList>
                  }

                </Paper>


                <Dialog
                  open={openPicture}
                  onClose={handleClosePicture}
                  aria-labelledby="alert-dialog-title-picture"
                  aria-describedby="alert-dialog-description-picture"
                  maxWidth="lg"
                >
                <DialogActions>
                  <Button onClick={handleClosePicture}>Close</Button>
                </DialogActions>
                  <DialogContent>
                  <img alt="" src={showPicture} height="700" />
                  </DialogContent>
                </Dialog>


                <Dialog
                  open={openDeleteFile}
                  onClose={handleCloseDeleteFile}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you want to delete this file?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDeleteFile}>Cancel</Button>
                    <Button onClick={deleteFile} autoFocus>
                      Delete file
                    </Button>
                  </DialogActions>
                </Dialog>

        </Grid>


        <Grid item xs={4}>

        <Paper className={classes.paper_custom}>

          <TableContainer>
           <Table size="small" aria-label="a dense table">
             <TableHead>
               <TableRow>
                 <TableCell align="center"><b>{my_months[((12 + this_month-4) % 12)]}</b></TableCell>
                 <TableCell align="center"><b>{my_months[((12 + this_month-3) % 12)]}</b></TableCell>
                 <TableCell align="center"><b>{my_months[((12 + this_month-2) % 12)]}</b></TableCell>
                 <TableCell align="center"><b>{my_months[((12 + this_month-1) % 12)]}</b></TableCell>
                 <TableCell align="center"><b>{my_months[((12 + this_month) % 12)]}</b></TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
                 <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                   <TableCell component="th" scope="row" align="center">{sold_Trend[0]}</TableCell>
                   <TableCell component="th" scope="row" align="center">{sold_Trend[1]}</TableCell>
                   <TableCell component="th" scope="row" align="center">{sold_Trend[2]}</TableCell>
                   <TableCell component="th" scope="row" align="center">{sold_Trend[3]}</TableCell>
                   <TableCell component="th" scope="row" align="center">{sold_Trend[4]}</TableCell>
                 </TableRow>
             </TableBody>
           </Table>
         </TableContainer>
        </Paper>

        <Paper className={classes.paper_custom}>

        <Typography variant="body1" className={classes.head_top_text}>
          <b>Unique product properties</b>
        </Typography>


        <Typography variant="body1" className={classes.head_text}>
          Price Shopify
        </Typography>
        <div>
          <TextField
            name="price"
            disabled
            id="outlined-size-small"
            value={price}
            size="small"
            fullWidth
            InputProps={{ style: { fontSize: 14 } }}
          />
        </div>

        <Typography variant="body1" className={classes.head_text}>
          Stock
        </Typography>
        <div>
          <TextField
            name="stock"
            disabled
            id="outlined-size-small"
            value={stock}
            size="small"
            fullWidth
            InputProps={{ style: { fontSize: 14 } }}
          />
        </div>

        <Typography variant="body1" className={classes.head_text}>
          EAN
        </Typography>
        <div>
          <TextField
            name="ean"
            disabled
            id="outlined-size-small"
            value={ean}
            size="small"
            fullWidth
            InputProps={{ style: { fontSize: 14 } }}
          />
        </div>


        <Typography variant="body1" className={classes.head_text}>
          Title (unique to this ean)
        </Typography>
        <div>
          <TextField
            name="title_website"
            id="outlined-size-small"
            value={title_website}
            size="small"
            fullWidth
            InputProps={{ style: { fontSize: 14 } }}
            onChange={onChange}
          />
        </div>


          <Typography variant="body1" className={classes.head_text}>
            Color
          </Typography>
          <div>
            <TextField
              name="color"
              id="outlined-size-small"
              value={color}
              size="small"
              fullWidth
              InputProps={{ style: { fontSize: 14 } }}
              onChange={onChange}
            />
          </div>

          <Typography variant="body1" className={classes.head_text}>
            Size
          </Typography>
          <div>
            <TextField
              name="size"
              id="outlined-size-small"
              value={size}
              size="small"
              fullWidth
              InputProps={{ style: { fontSize: 14 } }}
              onChange={onChange}
            />
          </div>

          <Typography variant="body1" className={classes.head_text}>
            Weight
          </Typography>
          <div>
            <TextField
              name="weight"
              id="outlined-size-small"
              value={weight}
              size="small"
              fullWidth
              InputProps={{ style: { fontSize: 14 } }}
              onChange={onChange}
            />
          </div>

          <Typography variant="body1" className={classes.head_text}>
            Lenght
          </Typography>
          <div>
            <TextField
              name="length"
              id="outlined-size-small"
              value={length}
              size="small"
              fullWidth
              InputProps={{ style: { fontSize: 14 } }}
              onChange={onChange}
            />
          </div>

          <Typography variant="body1" className={classes.head_text}>
            Width
          </Typography>
          <div>
            <TextField
              name="width"
              id="outlined-size-small"
              value={width}
              size="small"
              fullWidth
              InputProps={{ style: { fontSize: 14 } }}
              onChange={onChange}
            />
          </div>

          <Typography variant="body1" className={classes.head_text}>
            Height
          </Typography>
          <div>
            <TextField
              name="height"
              id="outlined-size-small"
              value={height}
              size="small"
              fullWidth
              InputProps={{ style: { fontSize: 14 } }}
              onChange={onChange}
            />
          </div>

        </Paper>

        <Paper className={classes.paper_custom}>
        <Typography variant="body1" className={classes.head_top_text}>
          <b>Sizing Charts</b>
        </Typography>

        <Button
            variant="outlined"
            component="label"
            size="small"
            startIcon={<FileUploadIcon />}
            style={{ textTransform: 'none', margin: '5px 20px 5px 0px' }}
          >
          <input hidden type="file" name="file" onChange={changeHandler} />
          Upload Sizing Chart
        </Button>


        </Paper>

        </Grid>

        </Grid>

      </div>
    }

    </div>

    </Grid>

    </Grid>


    </div>

  )
}
else {
  return (
      <Homepage />
)
}
}


export default () => <Product />
