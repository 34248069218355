import React, { useEffect, useState, useCallback, useRef } from 'react'
import Menu from './Menu.js';
import Homepage from './Homepage.js';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate} from 'react-router-dom';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAuth0 } from "@auth0/auth0-react";
import Chip from '@mui/material/Chip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import SettingsIcon from '@mui/icons-material/Settings';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const App = () => {

  const navigate = useNavigate();
  const handleOnClick = (url) => window.open(url, "_blank", "noreferrer");

  const [gridRef, setGridRef] = useState(null);

  const { logout, isAuthenticated } = useAuth0();
  const { loginWithRedirect } = useAuth0();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      foto_field: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      bulk_item: {
        width: '120px',
        margin: '0px 0px 10px 10px',
        textAlign: 'center',
        height: '58px',
        paddingTop: '5px',
        color: '#555e68',
        '&:hover': {
          cursor: 'pointer',
          color: '#9452c0',
          border: '1px solid #9452c0'
        },
      },
      bulk_icon: {
        color: '#000'
      },
      bulk_icon_inactive: {
        color: '#97999c'
      },
      basic_link: {
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline'
        }
      }
    }),
  );

  const exact_token_url = "https://start.exactonline.nl/api/oauth2/auth?client_id=%7Bbe737814-828b-40d3-994b-e17610076024%7D&redirect_uri=https%3A//pimly.nl/api/receive_exact_token&response_type=code"


  const [integrations, setIntegrations] = React.useState([]);

  useEffect(() => {
          const url = '/api/get_integrations';

          const requestOptions = {
            method: 'GET',
            headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
          };

          const fetchData = async () => {
              try {
                  const response = await fetch(url, requestOptions);
                  const json = await response.json();

                  setIntegrations(json)

             } catch (error) {
                 console.log("error", error);
             }
         };

         fetchData();
     }, []);


  if (isAuthenticated) {

  return (

    <div>

    <Grid container>

      <Grid item xs="auto">

        <Menu navigate={navigate} page={'dashboard'}/>

      </Grid>

      <Grid xs>

    <div>

    <div style={{marginTop: '55px', padding: '10px 20px 10px 20px'}}>


    <h4>Welcome</h4>


      </div>

      </div>

      </Grid>

    </Grid>

      </div>
  )
}
else {
  return (
      <Homepage />
)
}
}

export default () => <App />
