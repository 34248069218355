
import Button from '@mui/material/Button';
import { useAuth0 } from "@auth0/auth0-react";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

function Homepage() {

  const { loginWithRedirect } = useAuth0();

  return (

    <div>

        <Card style={{margin: '100px auto', backgroundColor: '#fbfbfb'}} sx={{ maxWidth: 345 }}>
          <CardContent style={{textAlign: 'center'}}>
              <img
              //src="https://aasprsoductimages.s3.eu-central-1.amazonaws.com/Logo_All_American_Sports_Transparant.png"
              src="https://aasproductimages.s3.eu-central-1.amazonaws.com/PimlyLogo.png"
              alt="Logo All-American Sports"
              height="60"
              />
          </CardContent>
          <CardActions>
          <Button style={{margin: '10px auto'}} variant="contained" onClick={() => loginWithRedirect()}>
            Log In</Button>
          </CardActions>
        </Card>

    </div>

  );
}

export default Homepage;
