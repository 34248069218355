import React, { useEffect, useState, useCallback, useRef } from 'react'
import Menu from './Menu.js';
import Homepage from './Homepage.js';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate} from 'react-router-dom';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAuth0 } from "@auth0/auth0-react";
import Chip from '@mui/material/Chip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import SettingsIcon from '@mui/icons-material/Settings';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const App = () => {

  const navigate = useNavigate();
  const handleOnClick = (url) => window.open(url, "_blank", "noreferrer");

  const [gridRef, setGridRef] = useState(null);

  const { logout, isAuthenticated } = useAuth0();
  const { loginWithRedirect } = useAuth0();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      add_button: {
        paddingLeft: '8px',
        color: 'blue',
        fontSize: '13px',
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      },
    }),
  );

  const classes = useStyles();

  const exact_token_url = "https://start.exactonline.nl/api/oauth2/auth?client_id=%7Bbe737814-828b-40d3-994b-e17610076024%7D&redirect_uri=https%3A//pimly.nl/api/receive_exact_token&response_type=code"


  const [integrations, setIntegrations] = React.useState([]);

  useEffect(() => {
          const url = '/api/get_integrations';

          const requestOptions = {
            method: 'GET',
            headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
          };

          const fetchData = async () => {
              try {
                  const response = await fetch(url, requestOptions);
                  const json = await response.json();

                  setIntegrations(json)

             } catch (error) {
                 console.log("error", error);
             }
         };

         fetchData();
     }, []);


  const [open, setOpen] = React.useState(false);

   const handleClickOpen = () => {
     setOpen(true);
   };

   const handleClose = () => {
     setOpen(false);
   };



  if (isAuthenticated) {

  return (

    <div>

    <Grid container>

      <Grid item xs="auto">

        <Menu navigate={navigate} page={'settings'}/>

      </Grid>

      <Grid xs>

    <div>

    <div style={{marginTop: '55px', padding: '10px 20px 10px 20px'}}>

    <p />

    <h4>Company</h4>

    All-American Sports

    <h4>Localization</h4>

    System language
    <br />
    Content languages
    <br />
    Main currency

    <h4>Accounts and access</h4>

    Manage users

      <p />
      <br />

    <h4>Marketplaces <span onClick={handleClickOpen} className={classes.add_button}>(Add)</span></h4>

    <Stack direction="row" spacing={2}>

      {integrations.filter(x => x.integration_type === 'Marketplace').map((value) => (
        <Card sx={{ maxWidth: 260 }}>
              <CardMedia
                component="img"
                height="146"
                image={value.cover_image}
                alt={value.name}
              />
              <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {value.description}
                </Typography>
              </CardContent>
              <CardActions>
              <IconButton aria-label="settings" color="primary" size="small" onClick={handleClickOpen}>
                <SettingsIcon />
                </IconButton>
                <IconButton aria-label="settings" color="primary" href={value.url} target="_blank" size="small">
                  <OpenInNewIcon />
                  </IconButton>
              </CardActions>
            </Card>
      ))}

    </Stack>

          <p />
          <br />

    <h4>Integrations <span onClick={handleClickOpen} className={classes.add_button}>(Add)</span></h4>

    <Stack direction="row" spacing={2}>

      {integrations.filter(x => x.integration_type !== 'Marketplace').map((value) => (
        <Card sx={{ maxWidth: 260 }}>
              <CardMedia
                sx={{ height: 140 }}
                image={value.cover_image}
                title={value.name}
              />
              <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {value.description}
                </Typography>
              </CardContent>
              <CardActions>
              <IconButton aria-label="settings" color="primary" size="small">
                <SettingsIcon />
                </IconButton>
                <IconButton aria-label="settings" color="primary" href={value.url} target="_blank" size="small">
                  <OpenInNewIcon />
                  </IconButton>
              </CardActions>
            </Card>
      ))}

    </Stack>


    <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Test Test
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">OK</Button>
        </DialogActions>
      </Dialog>


    <p />
    <a href={exact_token_url} target="_blank">Refresh exact token</a>

      </div>

      </div>

      </Grid>

    </Grid>

      </div>
  )
}
else {
  return (
      <Homepage />
)
}
}

export default () => <App />
